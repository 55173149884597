import {getOnboardingPhase} from "./Util";

let menaConstants = {
    BUSINESS_LINES : [{"id": "AIR", "name":"AIR", is_selected: false}, {"id": "AMZL", "name":"AMZL", is_selected: false}, {"id": "ATS", "name":"ATS", is_selected: false}, {"id": "FC", "name":"FC", is_selected: false}, {"id": "GSF", "name":"GSF", is_selected: false},{"id": "FM", "name":"FM", is_selected: false}],
    LO_DATA_SCHEMA_EXCEL_TO_JSON : {
        'Temp LO ID*': {
            prop: 'Temp LO ID',
            type: String
        },
        'Is RLC?*': {
            prop: 'Is RLC?',
            type: String
        },
        'Approval ID': {
            prop: 'ApprovalId',
            type: String
        },
        'DO_NOT_DELETE_LATEST_NEED_BY_DATE': {
            prop: 'Latest Need-by-Date ( YYYY-MM-DD )',
            type: String
        },
        'Business Line*': {
            prop: 'Business Line',
            type: String
        },
        'Site*': {
            prop: 'Site',
            type: String
        },
        'Job Role*': {
            prop: 'Job Role',
            type: String
        },
        'Head count*': {
            prop: 'Head count',
            type: String
        },
        'GENERAL_DO_NOT_DELETE': {
            prop: 'General',
            type: String
        },
        'Contract Type*': {
            prop: 'Contract Type',
            type: String
        },
        'Tenure': {
            prop: 'Tenure',
            type: String
        },
        'DO_NOT_DELETE_END_DATE': {
            prop: 'End Date ( YYYY-MM-DD )',
            type: String,
        },
        'WFS Watchers List': {
            prop: 'WFS Watchers List',
            type: String
        },
        'ALPS Code*': {
            prop: 'Alps Code',
            type: String,
        },
        'Cost Center*': {
            prop: 'Cost Center',
            type: String,
        },
        'Manager Alias*': {
            prop: 'Hiring Manager',
            type: String,
        },
        'Shift*': {
            prop: 'Shift',
            type: String,
        },
        'Daysoff*': {
            prop: 'DaysOff',
            type: String,
        },
        'Department': {
            prop: 'Department',
            type: String,
        },
    },
    LR_DATA_SCHEMA_EXCEL_TO_JSON : {
        'Temp LO ID*': {
            prop: 'Temp LO ID',
            type: String
        },
        'Vendor*': {
            prop: 'Vendor',
            type: String
        },
        'Job Type*': {
            prop: 'Job Type',
            type: String
        },
        'Head Count*': {
            prop: 'Head Count',
            type: String
        },
        'Recommended Lead Pool': {
            prop: 'Recommended Lead Pool',
            type: String
        },
        'Phase 1 : Head Count*': {
            prop: 'Phase 1 : General',
            type: String
        },
        'DO_NOT_DELETE_PHASE_ONE': {
            prop: 'Phase 1 : Need by Date ( YYYY-MM-DD )',
            type: String
        },
        'Phase 1 : Onboarding Shift ( HH:MM )*': {
            prop: 'Phase 1 : Onboarding Shift ( HH:MM )',
            type: String
        },
        'Phase 2 : Head Count': {
            prop: 'Phase 2 : General',
            type: String
        },
        'DO_NOT_DELETE_PHASE_TWO': {
            prop: 'Phase 2 : Need by Date ( YYYY-MM-DD )',
            type: String
        },
        'Phase 2 : Onboarding Shift ( HH:MM )': {
            prop: 'Phase 2 : Onboarding Shift ( HH:MM )',
            type: String
        },
        'Phase 3 : Head Count': {
            prop: 'Phase 3 : General',
            type: String
        },
        'DO_NOT_DELETE_PHASE_THREE': {
            prop: 'Phase 3 : Need by Date ( YYYY-MM-DD )',
            type: String
        },
        'Phase 3 : Onboarding Shift ( HH:MM )': {
            prop: 'Phase 3 : Onboarding Shift ( HH:MM )',
            type: String
        },
        'Phase 4 : Head Count': {
            prop: 'Phase 4 : General',
            type: String
        },
        'DO_NOT_DELETE_PHASE_FOUR': {
            prop: 'Phase 4 : Need by Date ( YYYY-MM-DD )',
            type: String
        },
        'Phase 4 : Onboarding Shift ( HH:MM )': {
            prop: 'Phase 4 : Onboarding Shift ( HH:MM )',
            type: String
        },
        'Phase 5 : Head Count': {
            prop: 'Phase 5 : General',
            type: String
        },
        'DO_NOT_DELETE_PHASE_FIVE': {
            prop: 'Phase 5 : Need by Date ( YYYY-MM-DD )',
            type: String
        },
        'Phase 5 : Onboarding Shift ( HH:MM )': {
            prop: 'Phase 5 : Onboarding Shift ( HH:MM )',
            type: String
        },
        'Phase 6 : Head Count': {
            prop: 'Phase 6 : General',
            type: String
        },
        'DO_NOT_DELETE_PHASE_SIX': {
            prop: 'Phase 6 : Need by Date ( YYYY-MM-DD )',
            type: String
        },
        'Phase 6 : Onboarding Shift ( HH:MM )': {
            prop: 'Phase 6 : Onboarding Shift ( HH:MM )',
            type: String
        },
    },
    LO_BULK_UPLOAD_EXCEL_FIELDS : [
        'Temp LO ID',
        'Is RLC?',
        'Approval ID',
        'Latest Need-by-Date ( YYYY-MM-DD )',
        'Business Line',
        'Site',
        'Job Role',
        'Head count',
        'Male',
        'Female',
        'Specially Abled',
        'Transgender',
        'Contract Type',
        'Tenure',
        'End Date ( YYYY-MM-DD )',
        'WFS Watchers List',
        'Alps Code',
        'Cost Center',
        'Hiring Manager',
        'Shift',
        'Daysoff',
        'Department'
    ],

    LR_BULK_UPLOAD_EXCEL_FIELDS : [
        'Temp LO ID',
        'Vendor',
        'Job Type',
        'Head Count',
        'Recommended Lead Pool',
        'Phase 1 : Male',
        'Phase 1 : Need by Date ( YYYY-MM-DD )',
        'Phase 1 : Onboarding Shift ( HH:MM )',
        'Phase 2 : Male',
        'Phase 2 : Need by Date ( YYYY-MM-DD )',
        'Phase 2 : Onboarding Shift ( HH:MM )',
        'Phase 3 : Male',
        'Phase 3 : Need by Date ( YYYY-MM-DD )',
        'Phase 3 : Onboarding Shift ( HH:MM )',
        'Phase 4 : Male',
        'Phase 4 : Need by Date ( YYYY-MM-DD )',
        'Phase 4 : Onboarding Shift ( HH:MM )',
        'Phase 5 : Male',
        'Phase 5 : Need by Date ( YYYY-MM-DD )',
        'Phase 5 : Onboarding Shift ( HH:MM )',
        'Phase 6 : Male',
        'Phase 6 : Need by Date ( YYYY-MM-DD )',
        'Phase 6 : Onboarding Shift ( HH:MM )'
    ],
    LO_DATA_SCHEMA_JSON_TO_EXCEL_SUCCESS : [
        {
            label: 'Labor Order ID',
            value: 'laborOrderId',
        },
        {
            label: 'Is RLC?',
            value: 'redLevelChange',
        },
        {
            label: 'ApprovalId',
            value: 'rlcApprovalId',
        },
        {
            label: 'Latest Need-by-Date ( YYYY-MM-DD )',
            value: 'latestNeedByDate',
        },
        {
            label: 'Business Line',
            value: 'businessLine',
        },
        {
            label: 'Site',
            value: 'site',
        },
        {
            label: 'Job Role',
            value: 'jobRole',
        },
        {
            label: 'Head count',
            value: 'headCount',
        },
        {
            label: 'Contract Type',
            value: 'contractType',
        },
        {
            label: 'Tenure',
            value: 'tenure',
        },
        {
            label: 'End Date ( YYYY-MM-DD )',
            value: 'endDate',
        },
        {
            label: 'WFS Watchers List',
            value: 'wfsWatchersList'
        },
        {
            label: 'Shift',
            value: 'shift',
        },
        {
            label: 'ALPS Code',
            value: 'alpsCode',
        },
        {
            label: 'Cost Center',
            value: 'costCenter',
        },
        {
            label: 'Dayoff',
            value: 'daysOff',
        },
        {
            label: 'Manager Alias',
            value: 'hiringManager',
        },
        {
            label: 'Department',
            value: 'department',
        },
        {
            label: 'Duplicate LO(s)(same hiring week, job role and site)',
            value: 'duplicateLos',
        }
    ],
    LR_DATA_SCHEMA_JSON_TO_EXCEL_SUCCESS : [
        {
            label: 'Requisition ID',
            value: 'requisitionId',
        },
        {
            label: 'Vendor',
            value: 'vendor',
        },
        {
            label: 'Job Type',
            value: 'jobType',
        },
        {
            label: 'Head Count',
            value: 'headCount',
        },
        {
            label: 'Recommended Lead Pool',
            value: 'recommendedLeadPool',
        },
        {
            label: 'Phase 1 : Head Count',
            value: 'phaseOneGeneral',
        },
        {
            label: 'Phase 1 : Need by Date ( YYYY-MM-DD )',
            value: 'phaseOneNeedByDate',
        },
        {
            label: 'Phase 1 : Onboarding Shift ( HH:MM )',
            value: 'phaseOneOnboardingShift'
        },
        {
            label: 'Phase 2 : Head Count',
            value: 'phaseTwoGeneral',
        },
        {
            label: 'Phase 2 : Need by Date ( YYYY-MM-DD )',
            value: 'phaseTwoNeedByDate',
        },
        {
            label: 'Phase 2 : Onboarding Shift ( HH:MM )',
            value: 'phaseTwoOnboardingShift'
        },
        {
            label: 'Phase 3 : Head Count',
            value: 'phaseThreeGeneral',
        },
        {
            label: 'Phase 3 : Need by Date ( YYYY-MM-DD )',
            value: 'phaseThreeNeedByDate',
        },
        {
            label: 'Phase 3 : Onboarding Shift ( HH:MM )',
            value: 'phaseThreeOnboardingShift'
        },
        {
            label: 'Phase 4 : Head Count',
            value: 'phaseFourGeneral',
        },
        {
            label: 'Phase 4 : Need by Date ( YYYY-MM-DD )',
            value: 'phaseFourNeedByDate',
        },
        {
            label: 'Phase 4 : Onboarding Shift ( HH:MM )',
            value: 'phaseFourOnboardingShift'
        },
        {
            label: 'Phase 5 : Head Count',
            value: 'phaseFiveGeneral',
        },
        {
            label: 'Phase 5 : Need by Date ( YYYY-MM-DD )',
            value: 'phaseFiveNeedByDate',
        },
        {
            label: 'Phase 5 : Onboarding Shift ( HH:MM )',
            value: 'phaseFiveOnboardingShift'
        },
        {
            label: 'Phase 6 : Head Count',
            value: 'phaseSixGeneral',
        },
        {
            label: 'Phase 6 : Need by Date ( YYYY-MM-DD )',
            value: 'phaseSixNeedByDate',
        },
        {
            label: 'Phase 6 : Onboarding Shift ( HH:MM )',
            value: 'phaseSixOnboardingShift'
        }
    ],
    LO_DATA_SCHEMA_JSON_TO_EXCEL_ERROR : [
        {
            label: 'Temp LO ID',
            value: 'tempLOID',
        },
        {
            label: 'Is RLC?',
            value: 'redLevelChange',
        },
        {
            label: 'ApprovalId',
            value: 'rlcApprovalId',
        },
        {
            label: 'Latest Need-by-Date ( YYYY-MM-DD )',
            value: 'latestNeedByDate',
        },
        {
            label: 'Business Line',
            value: 'businessLine',
        },
        {
            label: 'Site',
            value: 'site',
        },
        {
            label: 'Job Role',
            value: 'jobRole',
        },
        {
            label: 'Head count',
            value: 'headCount',
        },
        {
            label: 'Contract Type',
            value: 'contractType',
        },
        {
            label: 'Tenure',
            value: 'tenure',
        },
        {
            label: 'End Date ( YYYY-MM-DD )',
            value: 'endDate',
        },
        {
            label: 'WFS Watchers List',
            value: 'wfsWatchersList'
        },
        {
            label: 'Shift',
            value: 'shift',
        },
        {
            label: 'ALPS Code',
            value: 'alpsCode',
        },
        {
            label: 'Cost Center',
            value: 'costCenter',
        },
        {
            label: 'Dayoff',
            value: 'daysOff',
        },
        {
            label: 'Manager Alias',
            value: 'hiringManager',
        },
        {
            label: 'Department',
            value: 'department',
        },
        {
            label: 'Error',
            value: 'error',
        }
    ],
    LR_DATA_SCHEMA_JSON_TO_EXCEL_ERROR : [
        {
            label: 'Temp LO ID',
            value: 'tempLOID',
        },
        {
            label: 'Vendor',
            value: 'vendor',
        },
        {
            label: 'Job Type',
            value: 'jobType',
        },
        {
            label: 'Head Count',
            value: 'headCount',
        },
        {
            label: 'Recommended Lead Pool',
            value: 'recommendedLeadPool',
        },
        {
            label: 'Phase 1 : Head Count',
            value: 'phaseOneGeneral',
        },
        {
            label: 'Phase 1 : Need by Date ( YYYY-MM-DD )',
            value: 'phaseOneNeedByDate',
        },
        {
            label: 'Phase 1 : Onboarding Shift ( HH:MM )',
            value: 'phaseOneOnboardingShift'
        },
        {
            label: 'Phase 2 : Head Count',
            value: 'phaseTwoGeneral',
        },
        {
            label: 'Phase 2 : Need by Date ( YYYY-MM-DD )',
            value: 'phaseTwoNeedByDate',
        },
        {
            label: 'Phase 2 : Onboarding Shift ( HH:MM )',
            value: 'phaseTwoOnboardingShift'
        },
        {
            label: 'Phase 3 : Head Count',
            value: 'phaseThreeGeneral',
        },
        {
            label: 'Phase 3 : Need by Date ( YYYY-MM-DD )',
            value: 'phaseThreeNeedByDate',
        },
        {
            label: 'Phase 3 : Onboarding Shift ( HH:MM )',
            value: 'phaseThreeOnboardingShift'
        },
        {
            label: 'Phase 4 : Head Count',
            value: 'phaseFourGeneral',
        },
        {
            label: 'Phase 4 : Need by Date ( YYYY-MM-DD )',
            value: 'phaseFourNeedByDate',
        },
        {
            label: 'Phase 4 : Onboarding Shift ( HH:MM )',
            value: 'phaseFourOnboardingShift'
        },
        {
            label: 'Phase 5 : Head Count',
            value: 'phaseFiveGeneral',
        },
        {
            label: 'Phase 5 : Need by Date ( YYYY-MM-DD )',
            value: 'phaseFiveNeedByDate',
        },
        {
            label: 'Phase 5 : Onboarding Shift ( HH:MM )',
            value: 'phaseFiveOnboardingShift'
        },
        {
            label: 'Phase 6 : Head Count',
            value: 'phaseSixGeneral',
        },
        {
            label: 'Phase 6 : Need by Date ( YYYY-MM-DD )',
            value: 'phaseSixNeedByDate',
        },
        {
            label: 'Phase 6 : Onboarding Shift ( HH:MM )',
            value: 'phaseSixOnboardingShift'
        },

        {
            label: 'Error',
            value: 'error',
        }
    ],
}

export const TEMPLATES = {
    MENA_LO_DATA_TEMPLATE : [
        {
            label: 'Labor Order ID',
            value: 'laborOrderId',
        },
        {
            label: 'Is RLC?',
            value: 'redLevelChange',
        },
        {
            label: 'ApprovalId',
            value: 'rlcApprovalId',
        },
        {
            label: 'Latest Need-by-Date ( YYYY-MM-DD )',
            value: 'startDate',
        },
        {
            label: 'Business Line',
            value: 'businessLine',
        },
        {
            label: 'Site',
            value: 'site',
        },
        {
            label: 'Job Role',
            value: 'jobRole',
        },
        {
            label: 'Head count',
            value: 'headCount',
        },
        {
            label: 'Contract Type',
            value: (row) => row.tenure ? "Flexible Tenure" : "Fixed Tenure",
        },
        {
            label: 'Tenure',
            value: 'tenure',
        },
        {
            label: 'End Date ( YYYY-MM-DD )',
            value: 'endDate',
        },
        {
            label: 'WFS Watchers',
            value: (row) => row.wfsWatchersList ? row.wfsWatchersList.toString(): "",
        },
        {
            label: 'Shift',
            value: 'shift',
        },
        {
            label: 'ALPS Code',
            value: 'alpsCode',
        },
        {
            label: 'Cost Center',
            value: 'costCenter',
        },
        {
            label: 'Dayoff',
            value: 'daysOff',
        },
        {
            label: 'Manager Alias',
            value: 'hiringManager',
        },
        {
            label: 'Department',
            value: 'department',
        }
    ],
    MENA_LR_DATA_TEMPLATE : [
        {
            label: 'LaborOrder ID',
            value: 'laborOrderId',
        },
        {
            label: 'Requisition ID',
            value: 'requisitionId',
        },
        {
            label: 'Vendor',
            value: 'vendorName',
        },
        {
            label: 'Job Type',
            value: 'jobType',
        },
        {
            label: 'Head Count',
            value: 'headCount',
        },
        {
            label: 'Recommended Lead Pool',
            value: 'minLeadPool',
        },
        {
            label: 'Shift',
            value: 'shift',
        },
        {
            label: 'ALPS Code',
            value: 'alpsCode',
        },
        {
            label: 'Cost Center',
            value: 'costCenter',
        },
        {
            label: 'Dayoff',
            value: 'daysOff',
        },
        {
            label: 'Manager Alias',
            value: 'hiringManager',
        },
        {
            label: 'Phase 1 : Head Count',
            value: (row) => getOnboardingPhase(row,0).general
        },
        {
            label: 'Phase 1 : Need by Date ( YYYY-MM-DD )',
            value: (row) => getOnboardingPhase(row,0).startDate
        },
        {
            label: 'Phase 1 : Onboarding Shift ( HH:MM )',
            value: (row) => getOnboardingPhase(row,0).onboardingShift
        },
        {
            label: 'Phase 2 : Head Count',
            value: (row) => getOnboardingPhase(row,1).general
        },
        {
            label: 'Phase 2 : Need by Date ( YYYY-MM-DD )',
            value: (row) => getOnboardingPhase(row,1).startDate,
        },
        {
            label: 'Phase 2 : Onboarding Shift ( HH:MM )',
            value: (row) => getOnboardingPhase(row,1).onboardingShift
        },
        {
            label: 'Phase 3 : Head Count',
            value: (row) => getOnboardingPhase(row,2).general,
        },
        {
            label: 'Phase 3 : Need by Date ( YYYY-MM-DD )',
            value: (row) => getOnboardingPhase(row,2).startDate,
        },
        {
            label: 'Phase 3 : Onboarding Shift ( HH:MM )',
            value: (row) => getOnboardingPhase(row,2).onboardingShift
        },
        {
            label: 'Phase 4 : Head Count',
            value: (row) => getOnboardingPhase(row,3).general,
        },
        {
            label: 'Phase 4 : Need by Date ( YYYY-MM-DD )',
            value: (row) => getOnboardingPhase(row,3).startDate,
        },
        {
            label: 'Phase 4 : Onboarding Shift ( HH:MM )',
            value: (row) => getOnboardingPhase(row,3).onboardingShift
        },
        {
            label: 'Phase 5 : Head Count',
            value: (row) => getOnboardingPhase(row,4).general,
        },
        {
            label: 'Phase 5 : Need by Date ( YYYY-MM-DD )',
            value: (row) => getOnboardingPhase(row,4).startDate,
        },
        {
            label: 'Phase 5 : Onboarding Shift ( HH:MM )',
            value: (row) => getOnboardingPhase(row,4).onboardingShift
        },
        {
            label: 'Phase 6 : Head Count',
            value: (row) => getOnboardingPhase(row,5).general,
        },
        {
            label: 'Phase 6 : Need by Date ( YYYY-MM-DD )',
            value: (row) => getOnboardingPhase(row,5).startDate,
        },
        {
            label: 'Phase 6 : Onboarding Shift ( HH:MM )',
            value: (row) => getOnboardingPhase(row,5).onboardingShift
        },
        {
            label: 'Vendor Watchers',
            value: (row) => row.vendorWatchersList ? row.vendorWatchersList.toString(): "",
        },
        {
            label: 'WFS Watchers',
            value: (row) => row.wfsWatchersList ? row.wfsWatchersList.toString(): "",
        }
    ],
    LR_CANDIDATE_DATA_DOWNLOAD_TEMPLATE : [
        {
          label: 'Application Id',
          value: 'candidateId'
        },
        {
          label: 'BGV Vendor Code',
          value: 'bgvVendorCode'
        },
        {
          label: 'BGV Results',
          value: 'bgvResults'
        },
        {
          label: 'Assessment Score',
          value: 'assessmentScore'
        },
        {
          label: 'Assessment Result',
          value: 'assessmentResult'
        },
        {
          label: 'Out on road?',
          value: 'onRoad'
        },
        {
          label: 'Recruiter Name',
          value: 'recruiterName'
        },
        {
          label: 'Recruiter ID',
          value: 'recruiterId'
        },
        {
          label: 'Hiring Source',
          value: 'hiringSource'
        },
        {
          label: 'Is New-Hire/Re-hire',
          value: 'isRehire'
        },
        {
          label: 'City',
          value: 'city'
        },
        {
          label: 'Country',
          value: 'country'
        },
        {
          label: 'Marital Status',
          value: 'maritalStatus'
        },
        {
          label: 'Marital Status',
          value: 'maritalStatus'
        },
        {
          label: 'Educational Qualification',
          value: 'educationQaulification'
        },
        {
          label: 'Specially abled',
          value: 'speciallyAbled'
        },
        {
          label: 'Specially abled category',
          value: 'speciallyAbledCategory'
        },
        {
          label: 'Onboarding Date (DD-MMM-YYYY)',
          value: 'onboardingDate'
        },
        {
          label: 'Rehire Flag',
          value: 'rehireFlag'
        },
        {
            label: 'Part of Requisitions',
            value: (row) => row.activeRequisitions ? row.activeRequisitions.toString(): "",
        },
        {
            label: 'Duplicate Candidates',
            value: (row) => row.duplicateCandidateId ? row.duplicateCandidateId.toString(): "",
        },
        {
            label: 'Bgv Status',
            value: 'bgvStatus'
        },
        {
            label: 'Hiring Stage',
            value: 'hiringStage'
        },
        {
            label: 'Nationality',
            value: 'nationality'
        },
        {
            label: 'Responsible recruitment',
            value: 'responsibleRecruitment'
        },
        {
            label: 'Vendor KAM Id',
            value: 'modifiedByVendorKamId'
        },
        {
            label: 'Vendor KAM Name',
            value: 'modifiedByVendorKamName'
        },
        {
            label: 'Vendor Parent Id',
            value: 'modifiedByVendorId'
        },
        {
            label: 'Vendor Parent Name',
            value: 'modifiedByVendorName'
        },
        {
            label: 'Modified By',
            value: 'modifiedBy'
        },
        {
            label: 'Modified Date',
            value: 'modifiedDate'
        },
        {
            label: 'Created By',
            value: 'createdBy'
        },
        {
            label: 'Created Date',
            value: 'createdDate'
        }
    ]
}
export default menaConstants