import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
    Grid,
    Container,
    makeStyles,
    Link,
    Typography,
    Breadcrumbs, CircularProgress,
} from "@material-ui/core";
import ButtonPrimary from "../../../components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../components/ButtonSecondary/ButtonSecondary";
import FormPageTItle from "../../../components/FormPage/FormPageTItle";
import FormPageSubHeading from "../../../components/FormPage/FormPageSubHeading";
import InputLabel from "../../../components/FormPage/InputLabel";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import constants, {AuthorizationType} from "../../../Utils/Constants";
import { ActivityServices } from "../../../Services/activities";
import Formfieldstar from "../../../components/FormPage/FormFieldStar";
import { Formvalidationerror } from "../../../components/FormPage/FormValidationError";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { withAlertSnackBar } from "../../../HOComponents/AlertSnackBarHOC";
import Requisition from "../../../components/Requisition/Requisition";
import FormFieldView from "../../../components/FormPage/FormFieldView";
import {testPattern} from "../../../Utils/PatternUtil";
import {disablePastDate, isBeforeToday} from "../../../Utils/DateUtil";
import {getSelected} from "../../../Utils/Util";
import FormActionAlertDialog from "../../../components/FormPage/FormActionAlertDialog";
import Watcher from "../../../components/DropdownMenu/WatchersDropdownMenu";
import PageNotFound from "../../PageNotFound/PageNotFound";
import UnauthorizedAccessDialog from "../../PageNotFound/UnauthorizedAccessDialog";
import {useQuery} from "react-query";
import { useQueryClient} from 'react-query'
import {useGetRequisition} from "../../../ReactQuery/hooks/useGetRequisition";
import {useGetLaborOrder} from "../../../ReactQuery/hooks/useGetLaborOrder";
import {useGetAllLaborOrdersWithoutFilters} from "../../../ReactQuery/hooks/useGetAllLaborOrdersWithoutFilters";
import AutoComplete from "../../../components/FormPage/AutoComplete";
import {TextField} from "@mui/material";
import Switch from "@mui/material/Switch";
import {filterListByCountry, isMenaUser} from "../../../Utils/RegionUtil";

function CreateRequisition(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            "& > * + *": {
                marginTop: theme.spacing(2),
            },
        },
    }));

    const [vendors, setVendors] = useState([]);
    const [globalVendors, setGlobalVendors] = useState([]);
    let defaultJobTypes = isMenaUser() ? constants.ARRAYS.MENA_JOB_TYPES : constants.ARRAYS.IN_JOB_TYPES

    const defaultOnboardingPhases = [{id: 1, OnboardingPhaseData : {GeneralCount: "0", WomenCount: "0", DisabilityCount: "0", TransgenderCount: "0", StartDate: "",OnboardingShift:""}}];
    let history = useHistory();
    const classes = useStyles();
    const params = useParams();
    const [laborOrderIds, setLaborOrderIds] = useState([]);
    const [hiringWeek, setHiringWeek] = useState("");
    const [isVendorsDisabled, setIsVendorsDisabled] = useState(true);
    const [requisitions, setRequisitions] = useState([{id: 1, Vendors: vendors, JobTypes: defaultJobTypes, JobId: "" ,RequisitionData: {HeadCount: "", MinLeadPool: ""},  OnboardingPhases: defaultOnboardingPhases, isDisabled: isVendorsDisabled}]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [laborOrderIdData, setLaborOrderIdData] = useState("");
    const [isPrePopulated, setIsPrePopulated] = useState(false);
    const [showRemoveConfirmation, setShowRemoveConfirmation] = React.useState(false);
    const [watchersList, setWatchersList] = React.useState([]);
    const [isWatcherDisabled, setIsWatcherDisabled] = React.useState(true);
    const [isValidRequisitionId,setIsValidRequisitionId] = React.useState(true);
    const [showUnauthorizedDialog, setShowUnauthorizedDialog] = React.useState(false);
    const [authType, setAuthType] = React.useState("");
    const [vendorName, setVendorName] = useState("")
    const queryClient = useQueryClient();
    const [isNotificationNeeded, setIsNotificationNeeded] = useState(true);
    const [isPhoMandatory, setIsPhoMandatory] = useState(false);


    const {data: queryVendors} = useQuery("fetchVendors", ActivityServices.getAllVendors, {
        staleTime:1000*60*30
    });
    const {data: queryRequisition, isFetching: requisitionLoading, error: requisitionError} = useGetRequisition(params.LOid,params.id)
    const {data: queryLaborOrder, isFetching: laborOrderLoading, error: laborOrderError} = useGetLaborOrder(params.LOid, !!(params.LOid))
    const {data: queryLaborOrdersList, isFetching: laborOrdersLoading, error: laborOrdersListError} = useGetAllLaborOrdersWithoutFilters(!params.LOid)

    useEffect(() => {
        let newVendors = getFilteredVendors();
        let requisitionsL = requisitions.map(requisition => ({...requisition, Vendors: newVendors.length>0?newVendors:vendors, isDisabled: isVendorsDisabled}))
        setRequisitions(requisitionsL)

        if (params.id && !isPrePopulated) {
            setIsPrePopulated(true);
            prepopulate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendors,laborOrderIds, queryRequisition, requisitionError])

    const getFilteredVendors = () =>{
        let laborOrderIdsL = [...laborOrderIds]
        let selectedSite = "";
        laborOrderIdsL.forEach((laborOrder) => {
            if(laborOrder.is_selected) {
                selectedSite = laborOrder.site
            }
        });
        if(params.LOid && laborOrderIdsL.length>0){
            selectedSite = laborOrderIdsL[0].site
        }
        let newVendors = [];
        if(selectedSite !== "") {
            for(let index = 0; index < globalVendors.length; index++) {
                if(globalVendors[index].sites.includes(selectedSite) && !isBeforeToday(new Date(globalVendors[index].clrcValidity)) &&
                    !isBeforeToday(new Date(globalVendors[index].contractValidity)) && globalVendors[index].type === 'Manpower' && globalVendors[index].vendorStatus !== constants.STATUS.DELETED) {
                    if(!newVendors.some(vendor => vendor.name === globalVendors[index].name)) {
                        newVendors.push (globalVendors[index]);
                    }
                }
            }
        }
        return newVendors;
    }

    window.history.replaceState({}, document.title)
    async function fetchVendors() {
        let data = (queryVendors && queryVendors.data && queryVendors.data.vendors) || [];
        let vendorsL = data.map((vendor) => ({
            id: vendor.vendorId,
            is_selected: false,
            name: vendor.vendorName ? vendor.vendorName : "",
            email: vendor.correspondenceEmail ? vendor.correspondenceEmail : "",
            sites: vendor.sites ? vendor.sites : [],
            clrcValidity: vendor.clrcValidity,
            contractValidity: vendor.contractValidity,
            type: vendor.type,
            vendorStatus: vendor.vendorStatus
        }));
        setVendors(vendorsL)
        setGlobalVendors(vendorsL);
        setIsPrePopulated(false);
    }
    useEffect(() => {
        if(params.LOid)
        {
            let value1= {target:{value:params.LOid}}
            laborOrderIdsChangeHandler(value1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[laborOrderIds])

    useEffect( () => {

        fetchVendors()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryVendors, vendorName]);

    useEffect( () => {
        async function fetchLaborOrders() {
            if(params.LOid){
                if(laborOrderError){
                    if (laborOrderError && laborOrderError.data && (laborOrderError.data.message === constants.ERROR.UNAUTHORIZED_USER)) {
                        setShowUnauthorizedDialog(true);
                        setAuthType(AuthorizationType.SITE_BASED);
                    }
                    else if (laborOrderError && laborOrderError.data && (laborOrderError.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                        setShowUnauthorizedDialog(true);
                        setAuthType(AuthorizationType.BRASS);
                    }
                    else {
                        props.snackbarShowMessage(`${constants.ERROR.PREPOPULATE_ERROR}`, "error");
                    }
                }
                else if(queryLaborOrder && queryLaborOrder.data){
                    let laborOrder = (queryLaborOrder && queryLaborOrder.data) || {};
                    let laborOrdersL = [{
                        id: laborOrder.laborOrderId,
                        is_selected: false,
                        name: laborOrder.laborOrderId ? laborOrder.laborOrderId : "",
                        laborOrderHeadCount : laborOrder.headCount ? laborOrder.headCount.toString() : "0",
                        balance: laborOrder.balance ? laborOrder.balance.toString() : "0",
                        generalBalance: laborOrder.generalBalance ? laborOrder.generalBalance.toString() : "0",
                        womenBalance: laborOrder.womenBalance ? laborOrder.womenBalance.toString() : "0",
                        disabilityBalance: laborOrder.disabilityBalance ? laborOrder.disabilityBalance.toString() : "0",
                        transgenderBalance: laborOrder.transgenderBalance ? laborOrder.transgenderBalance.toString() : "0",
                        startDate : laborOrder.startDate ? laborOrder.startDate : "",
                        site: laborOrder.site ? laborOrder.site : "",
                        jobRole: laborOrder.jobRole ? laborOrder.jobRole : "",
                        tenure: laborOrder.tenure ? laborOrder.tenure : undefined,
                        endDate: laborOrder.endDate ? laborOrder.endDate : undefined,
                        wfsWatchersList: laborOrder.wfsWatchersList ? laborOrder.wfsWatchersList : [],
                        businessLine: laborOrder.businessLine ? laborOrder.businessLine : "",
                        country: laborOrder.country ? laborOrder.country : constants.INDIA_REGION
                    }]
                    laborOrdersL=laborOrdersL.filter(LO => LO.id ===params.LOid)
                    laborOrdersL = filterListByCountry(laborOrdersL)
                    laborOrdersL[0].is_selected=true
                    setLaborOrderIdData(laborOrdersL[0].name)
                    setIsFormSubmitted(false);
                    setLaborOrderIds(laborOrdersL);
                    setIsPrePopulated(false);
                    setIsVendorsDisabled(false)
                    populateWatchersByLaborOrder(laborOrdersL[0].wfsWatchersList);
                }

            }
            else{
                if(laborOrdersListError){
                    if (laborOrdersListError && laborOrdersListError.data && (laborOrdersListError.data.message === constants.ERROR.UNAUTHORIZED_USER)) {
                        setShowUnauthorizedDialog(true);
                        setAuthType(AuthorizationType.SITE_BASED);
                    }
                    else if (laborOrdersListError && laborOrdersListError.data && (laborOrdersListError.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                        setShowUnauthorizedDialog(true);
                        setAuthType(AuthorizationType.BRASS);
                    }
                }
                else{
                    let todayDate = new Date().toISOString().slice(0, 10)
                    let data = (queryLaborOrdersList && queryLaborOrdersList.data && queryLaborOrdersList.data.laborOrders) || [];
                    if(!params.id && !params.LOid) {
                        data = data.filter(item => item.balance !== 0 && item.laborOrderStatus !=="INACTIVE" && item.laborOrderStatus !== "DELETED" && Date.parse(item.startDate) >= Date.parse(todayDate));
                    }
                    let laborOrdersL = data.map((laborOrder) => ({
                        id: laborOrder.laborOrderId,
                        is_selected: false,
                        name: laborOrder.laborOrderId ? laborOrder.laborOrderId : "",
                        laborOrderHeadCount : laborOrder.headCount ? laborOrder.headCount.toString() : "0",
                        balance: laborOrder.balance ? laborOrder.balance.toString() : "0",
                        generalBalance: laborOrder.generalBalance ? laborOrder.generalBalance.toString() : "0",
                        womenBalance: laborOrder.womenBalance ? laborOrder.womenBalance.toString() : "0",
                        disabilityBalance: laborOrder.disabilityBalance ? laborOrder.disabilityBalance.toString() : "0",
                        transgenderBalance: laborOrder.transgenderBalance ? laborOrder.transgenderBalance.toString() : "0",
                        startDate : laborOrder.startDate ? laborOrder.startDate : "",
                        site: laborOrder.site ? laborOrder.site : "",
                        jobRole: laborOrder.jobRole ? laborOrder.jobRole : "",
                        tenure: laborOrder.tenure ? laborOrder.tenure : undefined,
                        endDate: laborOrder.endDate ? laborOrder.endDate : undefined,
                        wfsWatchersList: laborOrder.wfsWatchersList ? laborOrder.wfsWatchersList : [],
                        businessLine: laborOrder.businessLine ? laborOrder.businessLine : "",
                        country: laborOrder.country ? laborOrder.country : constants.INDIA_REGION
                    }));
                    let authorizedCountries = sessionStorage.getItem("authorizedCountries").split(',')
                    laborOrdersL=laborOrdersL.filter(LO => authorizedCountries.some((country) => (country === LO.country)))
                    setLaborOrderIds(laborOrdersL);
                    setIsPrePopulated(false);
                }

            }

        }
        fetchLaborOrders()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryLaborOrder, queryLaborOrdersList, laborOrdersListError, laborOrderError]);

    const prepopulate = async () => {
            if(requisitionError){
                if (requisitionError && requisitionError.data && (requisitionError.data.message === constants.ERROR.UNAUTHORIZED_USER)) {
                    setShowUnauthorizedDialog(true);
                    setAuthType(AuthorizationType.SITE_BASED);
                }
                else if (requisitionError && requisitionError.data && (requisitionError.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                    setShowUnauthorizedDialog(true);
                    setAuthType(AuthorizationType.BRASS);
                }
                else if (requisitionError && requisitionError.data && (requisitionError.data.message === constants.ERROR.REQUISITION_NOT_FOUND_MESSAGE)) {
                    setIsValidRequisitionId(false);
                } else {
                    props.snackbarShowMessage(
                        `${constants.ERROR.PREPOPULATE_ERROR}`,
                        "error"
                    );
                }
            }
            else{
                if(queryRequisition && queryRequisition.data){
                    let data = (queryRequisition && queryRequisition.data) || {};
                    let dataL = {
                        HiringWeek : data.hiringWeek,
                        VendorName: data.vendorName,
                        JobType : data.jobType,
                        LaborOrderId : data.laborOrderId,
                        RequisitionStatus: data.requisitionStatus,
                        JobId : data.jobId,
                        HeadCount: data.headCount,
                        MinLeadPool: data.minLeadPool,
                        VendorWatchersList : data.vendorWatchersList,
                        WfsWatchersList :data.wfsWatchersList,
                        OnboardingPhaseDetails:data.onboardingPhaseDetails,
                        PhoMandatory: (data.phoMandatory === undefined || data.phoMandatory === null) ? false : data.phoMandatory
                    }
                    populateFields(dataL)
                }
            }
    };

    const populateFields = async(data) => {
        setHiringWeek(data.HiringWeek);
        setVendorName(data.VendorName)
        let vendorsL = [...vendors]
        vendorsL = vendorsL.map((vendor) => ({
            id: vendor.id,
            is_selected: vendor.name === data.VendorName,
            name: vendor.name ? vendor.name : "",
            email: vendor.email ? vendor.email : "",
            sites: vendor.sites ? vendor.sites : "",
            clrcValidity: vendor.clrcValidity,
            contractValidity: vendor.contractValidity,
            type: vendor.type,
            vendorStatus: vendor.vendorStatus
        }))


        let jobTypesL = [...defaultJobTypes]
        jobTypesL = jobTypesL.map((jobType) => ({
            id: jobType.id,
            is_selected: jobType.name === data.JobType,
            name: jobType.name ? jobType.name : ""
        }))

        setLaborOrderIdData(data.LaborOrderId)

        let onboardingPhasesData = [];
        data.OnboardingPhaseDetails.forEach((phase, index) => {
            onboardingPhasesData.push({id: index + 1,OnboardingPhaseData: {GeneralCount: phase.general, WomenCount: phase.women, DisabilityCount: phase.peopleWithDisability, TransgenderCount: phase.transgender, StartDate: phase.startDate, OnboardingShift: phase.onboardingShift  } })})
        let requisition = [];
        let selectedSite = "";
        laborOrderIds.forEach((laborOrder) => {
            if(laborOrder.name === data.LaborOrderId) {
                selectedSite = laborOrder.site;
            }
        })
        let newVendors = [];
        if(selectedSite !== "") {
            for(let index = 0; index < vendorsL.length; index++) {
                if(vendorsL[index].sites.includes(selectedSite) && !isBeforeToday(new Date(vendorsL[index].clrcValidity)) &&
                    !isBeforeToday(new Date(vendorsL[index].contractValidity)) && vendorsL[index].type === 'Manpower' && vendorsL[index].vendorStatus !== constants.STATUS.DELETED) {
                    if(!newVendors.some(vendor => vendor.name === vendorsL[index].name))
                        newVendors.push(vendorsL[index]);
                }
            }
        }
        requisition.push({id: 1 , Vendors:newVendors.length>0? newVendors: vendors, JobTypes: jobTypesL,RequisitionStatus: data.RequisitionStatus, JobId: data.JobId , RequisitionData : {HeadCount: data.HeadCount.toString(), MinLeadPool: data.MinLeadPool ? data.MinLeadPool.toString() : ""},OnboardingPhases : onboardingPhasesData,
            VendorWatchersList : data.VendorWatchersList ? data.VendorWatchersList : []});
        setIsVendorsDisabled(false);
        setRequisitions(requisition);

        let wfsWatchersList = data.WfsWatchersList ? data.WfsWatchersList : [];
         prepopulateWatcher(data.LaborOrderId, wfsWatchersList);
        setIsValidRequisitionId(true);

        setIsPhoMandatory(data.PhoMandatory)
    }

    const prepopulateWatcher = (currentLaborOrderId, currentWatchers) => {
        let laborOrderIdsL = [...laborOrderIds];
        laborOrderIdsL.forEach((laborOrder) => {
            if(laborOrder.id === currentLaborOrderId) {
                const defaultWatcher = JSON.parse(sessionStorage.getItem('session_user')).id +
                    constants.WATCHER.EMAIL_SUFFIX;
                const loWatchersList = laborOrder.wfsWatchersList.filter((watcher) => watcher !== defaultWatcher);
                let newWatchersList = [...currentWatchers, defaultWatcher,...loWatchersList];
                //Getting only the unique watchers
                newWatchersList = newWatchersList.filter((watcher, pos) => newWatchersList.indexOf(watcher) === pos)
                setWatchersList(newWatchersList);
            }
        });
    }

    const validateLaborOrderIds = () => {
        let isFormValid = true;
        if (
            !laborOrderIds.find((laborOrder) => laborOrder.is_selected)
        )
            isFormValid = false;

        return isFormValid;
    }

    const validateRequisitions = () => {
        let isValid = true;
        requisitions.forEach((requisition) => {
            if(!validateRequisition(requisition)){
                isValid = false
            }
        });

        return isValid;
    }

    const validateRequisition = (requisition) => {
        let isValid = true;
            if(!requisition.JobTypes.find((requisitionItem) => requisitionItem.is_selected) ||
            !requisition.RequisitionData.HeadCount ||
            parseInt(requisition.RequisitionData.MinLeadPool) < parseInt(requisition.RequisitionData.HeadCount) ||
            !validateOnboardingPhases(requisition)
        )
            isValid = false;
        return isValid;
    }
    const validateOnboardingPhases = (requisition) => {
        let isValid = true;
        requisition.OnboardingPhases.forEach((phase) => {if(!validateOnboardingPhase(phase)){isValid = false}});
        return isValid
    }

    const validateOnboardingPhase = (onboardingPhase) => {
        let isValid = true;
        if(!onboardingPhase.OnboardingPhaseData.GeneralCount ||
            !onboardingPhase.OnboardingPhaseData.WomenCount ||
            !onboardingPhase.OnboardingPhaseData.DisabilityCount ||
            !onboardingPhase.OnboardingPhaseData.TransgenderCount ||
            !onboardingPhase.OnboardingPhaseData.StartDate ||
            (new Date(onboardingPhase.OnboardingPhaseData.StartDate)< new Date(disablePastDate(new Date(), -1)) ||
            !onboardingPhase.OnboardingPhaseData.OnboardingShift)
        )
            isValid = false;
        return isValid;
    }
    const validateRequisitionHeadCount = (requisition) => {
        let totalHeadCount = 0;
        let isValid = true;
        requisition.OnboardingPhases.forEach((phase) => {
            totalHeadCount+=(parseInt(phase.OnboardingPhaseData.GeneralCount) + parseInt(phase.OnboardingPhaseData.WomenCount) + parseInt(phase.OnboardingPhaseData.DisabilityCount) + parseInt(phase.OnboardingPhaseData.TransgenderCount))
        })
        if(totalHeadCount !== parseInt(requisition.RequisitionData.HeadCount)) {
            isValid = false;
        }
        return isValid;
    }
    const validatePhaseHeadCount = (requisition) => {
        let isValid = true;
        requisition.OnboardingPhases.forEach((requisitionItem) => {
            if(!(parseInt(requisitionItem.OnboardingPhaseData.GeneralCount) + parseInt(requisitionItem.OnboardingPhaseData.WomenCount) + parseInt(requisitionItem.OnboardingPhaseData.DisabilityCount) + parseInt(requisitionItem.OnboardingPhaseData.TransgenderCount))) {
                isValid = false;
            }
        })
        return isValid;
    }
    const getOnboardingPhases = (requisition) => {
        let onboardingPhases = [];
        requisition.OnboardingPhases.forEach((phase) => {onboardingPhases.push({"general": phase.OnboardingPhaseData.GeneralCount, "women": phase.OnboardingPhaseData.WomenCount, "peopleWithDisability": phase.OnboardingPhaseData.DisabilityCount, "transgender": phase.OnboardingPhaseData.TransgenderCount, "startDate": phase.OnboardingPhaseData.StartDate, "onboardingShift": phase.OnboardingPhaseData.OnboardingShift})})
        return onboardingPhases;
    }


    const formSubmit = () => {
        setIsFormSubmitted(true);
        setShowRemoveConfirmation(false);
        if(validateLaborOrderIds && validateRequisitions()) {
                let arePhasesCountValid = true;
                let arePhasesCountZero = false;
                requisitions.forEach((requisition) => {
                    if(!validateRequisitionHeadCount(requisition)) {
                        arePhasesCountValid = false;
                    }
                    if(!validatePhaseHeadCount(requisition)) {
                        arePhasesCountZero = true;
                    }
                } );
                if(arePhasesCountValid && !arePhasesCountZero) {
                    let requisitionsList = [];
                    requisitions.forEach((requisition) => {requisitionsList.push({headCount: parseInt(requisition.RequisitionData.HeadCount),
                        laborOrderId: params.LOid ? laborOrderIdData : laborOrderIds.find((laborOrder) => laborOrder.is_selected).name,
                        site: params.LOid ? laborOrderIds.find((laborOrder) => laborOrder.name === laborOrderIdData).site : getSelected(laborOrderIds).site,
                        jobRole: params.LOid ? laborOrderIds.find((laborOrder) => laborOrder.name === laborOrderIdData).jobRole : getSelected(laborOrderIds).jobRole,
                        jobType: requisition.JobTypes.find((jobType) => jobType.is_selected).name, vendorId: requisition.Vendors.find((vendor) => vendor.is_selected).id,
                        vendorName: requisition.Vendors.find((vendor) => vendor.is_selected).name,
                        vendorEmail : requisition.Vendors.find((vendor) => vendor.is_selected).email,
                        requisitionStatus: requisition.RequisitionStatus === constants.STATUS.WIP_FRONTEND ? constants.STATUS.WIP_DB : requisition.RequisitionStatus,
                        minLeadPool: requisition.RequisitionData.MinLeadPool ? parseInt(requisition.RequisitionData.MinLeadPool) : 0,
                        jobId: requisition.JobId ? requisition.JobId : "",
                        onboardingPhaseDetails: getOnboardingPhases(requisition), wfsWatchersList : watchersList,
                        modifiedBy: JSON.parse(sessionStorage.getItem('session_user')).id,
                        country: params.LOid ? laborOrderIds.find((laborOrder) => laborOrder.name === laborOrderIdData).country : getSelected(laborOrderIds).country,
                        vendorWatchersList : requisition.VendorWatchersList ? requisition.VendorWatchersList : [],
                        businessLine :  params.LOid ? laborOrderIds.find((laborOrder) => laborOrder.name === laborOrderIdData).businessLine : getSelected(laborOrderIds).businessLine,
                        loEndDate: params.LOid ? laborOrderIds.find((laborOrder) => laborOrder.name === laborOrderIdData).endDate : getSelected(laborOrderIds).endDate,
                        tenure: params.LOid ? laborOrderIds.find((laborOrder) => laborOrder.name === laborOrderIdData).tenure : getSelected(laborOrderIds).tenure,
                        notificationNeeded: params.id ? isNotificationNeeded : true,phoMandatory: params.id ? isPhoMandatory : false})})

                    let body = {
                        requisitions: requisitionsList
                    }
                        if (params.id) {
                            body = body.requisitions[0];
                            body.requisitionId = params.id;
                            ActivityServices.editRequisition(body)
                                .then((response) => {
                                    if(response.data.status === 'SUCCESS') {
                                        queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)
                                        queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
                                        props.snackbarShowMessage(
                                            `${constants.CRUD_SUCCESS_ALERTS.UPDATED}`
                                        );
                                        window.setTimeout(() => {
                                            history.push(`/${constants.REQUISITIONS.ROUTE.VIEW}/${params.LOid}&${params.id}`);
                                        }, 1000);
                                    }
                                    else if (response && response.data && (response.data.description === constants.ERROR.UNAUTHORIZED_USER)) {
                                        setShowUnauthorizedDialog(true);
                                        setAuthType(AuthorizationType.SITE_BASED);
                                    }
                                    else if (response && response.data && (response.data.description === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                                        setShowUnauthorizedDialog(true);
                                        setAuthType(AuthorizationType.BRASS);
                                    }
                                    else {
                                        props.snackbarShowMessage(
                                            `${constants.ERROR.UPDATE_ERROR} ${response.data.description}`,
                                            "error",
                                            "5000",
                                            ""
                                        );
                                    }

                                })
                                .catch((response, error) => {
                                    props.snackbarShowMessage(
                                            `${constants.ERROR.UPDATE_ERROR} ${response.data.message}`,
                                            "error",
                                            "5000",
                                            response.data.message
                                        );

                                });
                        } else {
                            ActivityServices.addRequisition(body)
                                .then((response) => {
                                    if(response.data.status === 'Success'){
                                        queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)
                                        queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
                                        props.snackbarShowMessage(
                                            `${constants.CRUD_SUCCESS_ALERTS.CREATED}`
                                        );
                                        window.setTimeout(() => {
                                            history.push(`/${constants.REQUISITIONS.ROUTE.MAIN}`);
                                        }, 1000)
                                    }
                                    else{
                                        props.snackbarShowMessage(
                                            `${response.data.description}`,"error"
                                        );
                                    }

                                })
                                .catch((response, error) => {
                                    if (response && response.data && (response.data.message === constants.ERROR.UNAUTHORIZED_USER)) {
                                        setShowUnauthorizedDialog(true);
                                        setAuthType(AuthorizationType.SITE_BASED);
                                    }
                                    else if (response && response.data && (response.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                                        setShowUnauthorizedDialog(true);
                                        setAuthType(AuthorizationType.BRASS);
                                    }
                                    else {
                                        props.snackbarShowMessage(
                                            `${constants.ERROR.CREATE_ERROR} ${response.data.message}`,
                                            "error",
                                            "5000",
                                            response.data.error
                                        );
                                    }
                                });
                        }
                }
                else {
                    setIsFormSubmitted(false);
                    if(!arePhasesCountValid) {
                        props.snackbarShowMessage(
                            `${constants.ERROR.REQUISITION_HEAD_COUNT_ERROR}`,
                            "error",
                            "5000",
                            constants.ERROR.REQUISITION_HEAD_COUNT_ERROR
                        );
                    }
                    else if(arePhasesCountZero) {
                        props.snackbarShowMessage(
                            `${constants.ERROR.REQUISITION_PHASE_HEAD_COUNT_ERROR}`,
                            "error",
                            "5000",
                            constants.ERROR.REQUISITION_PHASE_HEAD_COUNT_ERROR
                        );
                    }

                }
        }
    };

    const cancelActivity = () => {
        history.goBack();
    };

    const populateWatchersByLaborOrder = (loWatchers) => {
        const defaultWatcher = JSON.parse(sessionStorage.getItem('session_user')).id +
            constants.WATCHER.EMAIL_SUFFIX;
        const siteWatchersList = loWatchers.filter((watcher) => watcher !== defaultWatcher);
        setWatchersList([defaultWatcher,...siteWatchersList]);
    }

    const laborOrderIdsChangeHandler = (event) => {
        fetchVendors()
        setIsFormSubmitted(false);
        let value = event.target.innerHTML;
        setIsWatcherDisabled(value === "0");
        let laborOrderIdsL = [...laborOrderIds];
        let selectedSite = "";
        laborOrderIdsL.forEach((laborOrder) => {
            laborOrder.is_selected = value && laborOrder.id === value;
            if(laborOrder.is_selected) {
                selectedSite = laborOrder.site
                populateWatchersByLaborOrder(laborOrder.wfsWatchersList)
            }
        });
        if(params.LOid && laborOrderIdsL.length>0){
            selectedSite = laborOrderIdsL[0].site
        }
        const newRequisitions = [...requisitions]
        let newVendors = [];
        if(value!=="0" && selectedSite !== "") {
            for(let index = 0; index < globalVendors.length; index++) {
                if(globalVendors[index].sites.includes(selectedSite) && !isBeforeToday(new Date(globalVendors[index].clrcValidity)) &&
                    !isBeforeToday(new Date(globalVendors[index].contractValidity)) && globalVendors[index].type === 'Manpower') {
                    if(!newVendors.some(vendor => vendor.name === globalVendors[index].name)) {
                        newVendors.push (globalVendors[index]);
                    }
                }
            }
        }


        if(params.id){
            newVendors = newVendors.map((vendor) => ({
                ...vendor,
                    is_selected: vendor.name === vendorName
            }))
        }

        let requisitionsL = newRequisitions.map(el => ({...el, Vendors: newVendors, isDisabled: value ==="0"}))
        setVendors(newVendors)
        setRequisitions(requisitionsL)
        setIsVendorsDisabled(value === "0")
        setRequisitions(newRequisitions);
        if(!params.LOid){
            setLaborOrderIds(laborOrderIdsL);
        }
    };
    const vendorChangeHandler = (id, value) => {
        setIsFormSubmitted(false);
        let requisitionsL = [...requisitions];
        const index = requisitionsL.findIndex(requisition => requisition.id === id);
        let vendorsL = JSON.parse(JSON.stringify(requisitionsL[index].Vendors));
        vendorsL.forEach((vendor) => {
            vendor.is_selected = value && vendor.id === value;
        });
        requisitionsL[index].Vendors = vendorsL;
        requisitionsL.forEach((requisition) => {
            const currentOnboardingPhases = [...requisition.OnboardingPhases]
            let newOnboardingPhases = [];
            currentOnboardingPhases.forEach((phase) => {newOnboardingPhases.push({id: phase.id, OnboardingPhaseData: {GeneralCount: phase.OnboardingPhaseData.GeneralCount, WomenCount: phase.OnboardingPhaseData.WomenCount, DisabilityCount: phase.OnboardingPhaseData.DisabilityCount, TransgenderCount: phase.OnboardingPhaseData.TransgenderCount, StartDate: phase.OnboardingPhaseData.StartDate, OnboardingShift: phase.OnboardingPhaseData.OnboardingShift}})});
            requisition.OnboardingPhases = newOnboardingPhases;
        })
        setRequisitions(requisitionsL);
    };
    const jobTypeChangeHandler = (id, value) => {
        setIsFormSubmitted(false);
        let requisitionsL = [...requisitions];
        const index = requisitionsL.findIndex(requisition => requisition.id === id);
        let jobTypesL = [...requisitionsL[index].JobTypes];
        jobTypesL.forEach((jobType) => {
            jobType.is_selected = value && jobType.id === value;
        });
        requisitionsL[index].JobTypes = jobTypesL;
        setRequisitions(requisitionsL);
    };

    const handleCreateRequisition = (event) => {
        setIsFormSubmitted(false);
        event.preventDefault();
        let newVendors = getFilteredVendors()
        const requisition = {id: requisitions[requisitions.length - 1].id + 1 ,Vendors:newVendors.length>0?newVendors: vendors, JobTypes: defaultJobTypes, RequisitionData: {HeadCount: "", MinLeadPool: ""}, OnboardingPhases: defaultOnboardingPhases, isDisabled : isVendorsDisabled};
        const newRequisitions =  [...requisitions];
        newRequisitions.push(requisition);
        setRequisitions(newRequisitions);
    }

    const handleDeleteRequisition = (id) => {
        setIsFormSubmitted(false);
        const newRequisitions = requisitions.filter(item => item.id !== id);
        setRequisitions(newRequisitions);
    }

    const handleCreateOnboardingPhase = (id) => {
        setIsFormSubmitted(false);
        const index = requisitions.findIndex(requisition => requisition.id === id);
        const onboardingPhase = {id: (requisitions[index].OnboardingPhases)[requisitions[index].OnboardingPhases.length - 1].id + 1 , OnboardingPhaseData : {GeneralCount: "0", WomenCount: "0", DisabilityCount: "0", TransgenderCount: "0", StartDate: "", OnboardingShift: ""}}
        const newRequisitions = [...requisitions];
        newRequisitions[index].OnboardingPhases.push(onboardingPhase);
        setRequisitions(newRequisitions);
    }

    const handleDeleteOnboardingPhase = (requisitionId, onboardingPhaseId) => {
        setIsFormSubmitted(false);
        const requisitionIndex = requisitions.findIndex(requisition => requisition.id === requisitionId);
        const newRequisitions = [...requisitions];
        const newOnboardingPhases = newRequisitions[requisitionIndex].OnboardingPhases.filter(phase => phase.id !== onboardingPhaseId);
        newRequisitions[requisitionIndex].OnboardingPhases = newOnboardingPhases;
        setRequisitions(newRequisitions);
    }


    const handleRequisitionDataChange = (id, e) => {
        setIsFormSubmitted(false);
        let requisitionsL = [...requisitions];
        const index = requisitionsL.findIndex(requisition => requisition.id === id);
        let requisitionData = requisitionsL[index].RequisitionData;
        if(e.target.name === "MinLeadPool" || e.target.name === "HeadCount") {
            let reg = new RegExp('^[0-9]*$');
            if(testPattern(e.target.value, reg)){
                requisitionData[e.target.name] = e.target.value;
                requisitionsL[index].RequisitionData = requisitionData;
                setRequisitions(requisitionsL);
            }
        }
        else {
            requisitionData[e.target.name] = e.target.value;
            requisitionsL[index].RequisitionData = requisitionData;
            setRequisitions(requisitionsL);
        }

    }

    const handleOnboardingPhaseDataChange = (requisitionId, onboardingPhaseId, e) => {
        setIsFormSubmitted(false);
        const requisitionIndex = requisitions.findIndex(requisition => requisition.id === requisitionId);
        const newRequisitions = [...requisitions];
        const newOnboardingPhases = newRequisitions[requisitionIndex].OnboardingPhases;
        const onboardingPhaseIndex = newOnboardingPhases.findIndex(phase => phase.id === onboardingPhaseId);
        if(e.target.name === "StartDate") {
            for(let index= onboardingPhaseIndex + 1 ; index<newOnboardingPhases.length; index++) {
                newOnboardingPhases[index].OnboardingPhaseData[e.target.name] = "";
            }
            newOnboardingPhases[onboardingPhaseIndex].OnboardingPhaseData[e.target.name] = e.target.value;
            newRequisitions[requisitionIndex].OnboardingPhases = newOnboardingPhases;
            setRequisitions(newRequisitions);
        } else if(e.target.name==="OnboardingShift"){
            const hours = e.target.value.getHours();
            const mins = e.target.value.getMinutes();
            const onboardingTime = (hours<10? "0"+hours:hours) +":" +(mins<10?"0"+mins:mins);

            newOnboardingPhases[onboardingPhaseIndex].OnboardingPhaseData[e.target.name] = onboardingTime;
            newRequisitions[requisitionIndex].OnboardingPhases = newOnboardingPhases;
            setRequisitions(newRequisitions);
        }
        else {
            let reg = new RegExp('^[0-9]*$');
            if(testPattern(e.target.value, reg)){
                newOnboardingPhases[onboardingPhaseIndex].OnboardingPhaseData[e.target.name] = e.target.value;
                newRequisitions[requisitionIndex].OnboardingPhases = newOnboardingPhases;
                setRequisitions(newRequisitions);
            }
        }
    }
    const getLaborOrderFieldData = (category) => {
        let balance = "";
        if(params.id) {
            laborOrderIds.forEach((laborOrder) => {if(laborOrder.name === laborOrderIdData){balance = laborOrder[category]}});
        }
        else if(params.LOid) {
            laborOrderIds.forEach((laborOrder) => {balance = laborOrder[category]});
        }
        else{
            laborOrderIds.forEach((laborOrder) => {if(laborOrder.is_selected){balance = laborOrder[category]}});
        }
        return balance
    }

    const updateRequisitionConfirm = () => {
        setShowRemoveConfirmation(true);
    }


    return (
        !isValidRequisitionId ? <PageNotFound/> :
            <div className="amz_content_view mb-100">
            <Container maxWidth="lg">

                <Grid container alignItems={"center"} direction="row" justify="space-around">

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container direction="row" justify="flex-start" alignItems="center">
                        <div className={classes.root}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {params.relatedID && params.relatedName && (
                                    <Typography color="textPrimary">
                                        Create Related Activity
                                    </Typography>
                                )}
                                {!params.relatedID && !params.relatedName && (
                                    <Link className="primary_color"
                                          onClick={() =>
                                              history.push(`/${constants.REQUISITIONS.ROUTE.MAIN}`)
                                          }
                                    >
                                        {constants.REQUISITIONS.TITLE}
                                    </Link>
                                )}
                                {!params.id && !params.relatedID && !params.relatedName && (
                                    <Typography color="textPrimary">
                                        {constants.BUTTON.CREATE}{" "}
                                        {constants.REQUISITIONS.TITLE_SINGULAR}
                                    </Typography>
                                )}
                                {params.id && (
                                    <Typography color="textPrimary">
                                        {params.id}
                                    </Typography>
                                )}
                            </Breadcrumbs>
                        </div>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container direction="row" justify="flex-end" alignItems="flex-start">
                        <Watcher  watchersList={watchersList} setWatchersList={setWatchersList}
                                  isUpdateRequired={false} isWatcherDisabled={isWatcherDisabled} />
                        <ButtonSecondary Title="Cancel" onClick={cancelActivity} ButtonIcon={<CloseIcon />} tab_index="0"/>
                        <ButtonPrimary Title={params.id ? "Update" : "Save"} onClick={params.id ? updateRequisitionConfirm : formSubmit} ButtonIcon={<CheckIcon />} tab_index="0"/>

                    </Grid>

                    {params.id ?  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container direction="row" justify="flex-end" alignItems="flex-start" style = {{marginTop: "1rem"}}>
                        <Grid>
                        <InputLabel Title="Send Notifications" />
                        <Switch style = {{color: "#007980"}} label={"switch"} onChange={() => {setIsNotificationNeeded(!isNotificationNeeded)}} checked={isNotificationNeeded} size="small"/>
                        </Grid>
                        <Grid style={{marginLeft:"0.5rem"}}>
                            <InputLabel Title="Is PHO Mandatory" />
                        <Switch style = {isPhoMandatory?{color:"#007980"}:{color:"slategrey"}} label={"switch"} onChange={() => {setIsPhoMandatory(!isPhoMandatory)}} checked={isPhoMandatory} size="small"/>
                        </Grid>
                    </Grid> : null}

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container direction="row" justify="space-around" alignItems="center">
                        <div divider></div>
                        {params.id && <FormPageTItle Title={""} />}
                        <div divider></div>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                        <FormPageSubHeading Title={constants.FORM_DETAILS.BASIC} />
                    </Grid>

                    {(requisitionLoading || laborOrderLoading || laborOrdersLoading) && <CircularProgress/>}
                    {!(requisitionLoading || laborOrderLoading || laborOrdersLoading) && <Grid container alignItems="top" className="form_group_list">

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Labor order :"/>
                                    <Formfieldstar/>
                                </Grid>
                                {!params.id && !params.LOid && <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <AutoComplete error id="OrganizationStatus" name="OrganizationStatus"
                                                  options={laborOrderIds} onChange={laborOrderIdsChangeHandler}
                                                  fieldName="labor orders" tab_index="0"
                                                  renderInput={(params) => <TextField {...params} label="Controllable" />}/>
                                    {isFormSubmitted && !laborOrderIds.find((id) => id.is_selected) && (
                                        <Formvalidationerror fieldName="Labor Order"/>
                                    )}
                                </Grid>}
                                {(params.id || params.LOid) && <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={laborOrderIdData}/>
                                </Grid>}

                            </Grid>
                        </Grid>
                        {requisitions[0].JobId && requisitions[0].JobId !== "NA" && requisitions[0].JobId !== "" &&
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <InputLabel Title="Job Id:"/>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        {<FormFieldView Content={requisitions[0].JobId}/>}
                                    </Grid>
                                </Grid>
                            </Grid>}
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Head count:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {<FormFieldView Content={getLaborOrderFieldData("laborOrderHeadCount")}/>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Type of contract:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {<FormFieldView Content={getLaborOrderFieldData("tenure") ? "Fixed Tenure" :
                                        getLaborOrderFieldData("endDate") ? "Flexible Tenure" : undefined}/>}
                                </Grid>
                            </Grid>
                        </Grid>
                        {getLaborOrderFieldData("tenure") && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Tenure:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {<FormFieldView Content={getLaborOrderFieldData("tenure")}/>}
                                </Grid>
                            </Grid>
                        </Grid>}
                        {getLaborOrderFieldData("endDate") && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="End date:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {<FormFieldView Content={getLaborOrderFieldData("endDate")}/>}
                                </Grid>
                            </Grid>
                        </Grid>}
                        {getLaborOrderFieldData("site") && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Site:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {<FormFieldView Content={getLaborOrderFieldData("site")}/>}
                                </Grid>
                            </Grid>
                        </Grid>}
                        {params.id && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Hiring Week:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {<FormFieldView Content={hiringWeek}/>}
                                </Grid>
                            </Grid>
                        </Grid>}
                        {isMenaUser() && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Balance:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {<FormFieldView Content={getLaborOrderFieldData("generalBalance")}/>}
                                </Grid>
                            </Grid>
                        </Grid>}
                    </Grid>}
                    {!isMenaUser() && <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                        <FormPageSubHeading Title={constants.FORM_DETAILS.BALANCE} />
                    </Grid>}
                    {(requisitionLoading || laborOrderLoading || laborOrdersLoading) && <CircularProgress/>}
                    {!(requisitionLoading || laborOrderLoading || laborOrdersLoading) && !isMenaUser() && <Grid container alignItems="center" className="form_group_list">
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Male:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {<FormFieldView Content={getLaborOrderFieldData("generalBalance")}/>}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Specially Abled:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {<FormFieldView Content={getLaborOrderFieldData("disabilityBalance")}/>}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Female:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {<FormFieldView Content={getLaborOrderFieldData("womenBalance")}/>}
                                </Grid>
                            </Grid>
                        </Grid>

                         <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Transgender:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {<FormFieldView Content={getLaborOrderFieldData("transgenderBalance")}/>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                        <FormPageSubHeading Title={constants.FORM_DETAILS.CREATE_REQUISITION} />
                    </Grid>

                    {(requisitionLoading || laborOrderLoading || laborOrdersLoading) && <CircularProgress/>}
                    {!(requisitionLoading || laborOrderLoading || laborOrdersLoading) && requisitions.map(requisition => <Requisition IsFormSubmitted = {isFormSubmitted} StartDate = {getLaborOrderFieldData("startDate")} EndDate = {getLaborOrderFieldData("endDate")}  count = {requisitions.findIndex(item => item.id === requisition.id) + 1} length = {requisitions.length} Requisition = {requisition} onVendorChange = {vendorChangeHandler} onJobTypeChange = {jobTypeChangeHandler} onRequisitionDataChange = {handleRequisitionDataChange} onboardingPhaseDataChange = {handleOnboardingPhaseDataChange} onCreateRequisition = {handleCreateRequisition} onDeleteRequisition = {handleDeleteRequisition} onCreateOnboardingPhase = {handleCreateOnboardingPhase} onDeleteOnboardingPhase = {handleDeleteOnboardingPhase}  isUpdate = {params.id} Requisitions = {requisitions} />)}
                </Grid>

                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container direction="row" justify="flex-end" alignItems="flex-start">
                            <ButtonSecondary Title="Cancel" onClick={cancelActivity} ButtonIcon={<CloseIcon />} tab_index="0"/>
                            <ButtonPrimary Title={params.id ? "Update" : "Save"} onClick={params.id ? updateRequisitionConfirm : formSubmit} ButtonIcon={<CheckIcon />} tab_index="0"/>
                    </Grid>
                </Grid>
            </Container>
            {showRemoveConfirmation &&
            <FormActionAlertDialog Message="Do you confirm to edit the requisition? This activity may have implications to the Vendor assignment and associated hiring requirements" handleYes={formSubmit} handleNo={() => { setShowRemoveConfirmation(false) }} />
            }
                {showUnauthorizedDialog && (
                    <UnauthorizedAccessDialog
                        handleCancel={() => {
                            setShowUnauthorizedDialog(false);
                        }}
                        authorizationType={authType}
                        snackbarShowMessage={props.snackbarShowMessage}
                    />
                )}
        </div>

    );
}

export default withAlertSnackBar(CreateRequisition);