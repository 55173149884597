import React, {useState, useMemo} from "react";
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import FormPageTItle from "../../../components/FormPage/FormPageTItle";
import FormPageSubHeading from "../../../components/FormPage/FormPageSubHeading";
import InputLabel from "../../../components/FormPage/InputLabel";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory, useParams } from "react-router";
import FormFieldView from "../../../components/FormPage/FormFieldView";
import constants, {
    AuthorizationType,
    CANDIDATE_BALANCE_LIST,
    LR_CANDIDATE_DOWNLOAD_SCHEMA
} from "../../../Utils/Constants"
import { useEffect } from "react";
import { ActivityServices } from "../../../Services/activities";
import FormActionAlertDialog from "../../../components/FormPage/FormActionAlertDialog";
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import ViewOnboardingPhase from "./ViewOnboardingPhase";
import { withAlertSnackBar } from "../../../HOComponents/AlertSnackBarHOC";
import Demo from "../../../components/DropdownMenu/customDropdownMenu"
import {CheckCircleSharp, SyncDisabled} from "@material-ui/icons";
import ButtonSecondary from "../../../components/ButtonSecondary/ButtonSecondary";
import xlsx from 'json-as-xlsx';
import Watcher from "../../../components/DropdownMenu/WatchersDropdownMenu";
import PageNotFound from "../../PageNotFound/PageNotFound";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import InfoHelpIcon from "../../../components/FormPage/InfoHelpIcon";
import CommentsLog from "../../Common/CommentsLog";
import FilterListIcon from "@material-ui/icons/FilterList";
import UnauthorizedAccessDialog from "../../PageNotFound/UnauthorizedAccessDialog";
import UndoIcon from "@material-ui/icons/Undo";
import { useLocation} from "react-router-dom";
import {useQueryClient} from "react-query";
import {useGetAllComments} from "../../../ReactQuery/hooks/useGetAllComments";
import {useGetRequisition} from "../../../ReactQuery/hooks/useGetRequisition";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import {Tab, Tabs} from "@mui/material";
import AuditLogCards from "../../Common/AuditTrail/AuditLogCards";
import ViewMenaOnboardingPhase from "./ViewMenaOnboardingPhase";
import {isMenaUser} from "../../../Utils/RegionUtil";
import {getUserCountry} from "../../../Utils/Util";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`related-tab-${index}`}
            aria-labelledby={`related-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function ViewRequisition(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        tabs: {
            "& .MuiTabs-indicator": {
                backgroundColor: "#006170",
                height: 3,
            },
            "& .MuiTab-root.Mui-selected": {
                color: '#006170'
            }
        }
    }));
    let history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const [formData, setFormData] = React.useState({});
    const params = useParams();
    const [showRemoveConfirmation, setShowRemoveConfirmation] = React.useState(false);
    const [showInactiveConfirmation,setShowInactiveConfirmation] = React.useState(false);
    const [showActiveConfirmation,setShowActiveConfirmation] = React.useState(false);
    const [phases, setPhases] = useState([]);
    const [watchersList, setWatchersList] = React.useState([]);
    const [isValidRequisitionId,setIsValidRequisitionId] = React.useState(true);
    const [showMarkFulfilledConfirmation, setMarkFulfilledConfirmation] = React.useState(false);
    const [comments, setComments] = React.useState([]);
    const [showUnauthorizedDialog, setShowUnauthorizedDialog] = React.useState(false);
    const [authType, setAuthType] = React.useState("");
    const [showRevokeFulfilledConfirmation, setRevokeFulfilledConfirmation] = React.useState(false);
    const queryClient = useQueryClient();

    const {data: queryComments, isFetching: commentsLoading} = useGetAllComments(params.id)
    const {data : queryRequisition, isFetching: requisitionLoading, error: requisitionError} = useGetRequisition(params.LOid, params.id, !(location.state && location.state.requisition))
    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function LinkTab(props) {
        return (
            <Tab
                style={{textTransform:"capitalize"}}
                component="a"
                onClick={(event) => {
                    event.preventDefault();
                }}
                {...props}
            />
        );
    }

    useEffect(() => {
        if (params.id ) {
            prepopulate()
            // getCommentsByRequisitionId()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryRequisition, requisitionError])

    window.history.replaceState({}, document.title)

    const prepopulate = async () => {
        if(location.state && location.state.requisition){
            setWatchersList(location.state.requisition.WfsWatchersList);
            setFormData({...location.state.requisition});
            setPhases(location.state.requisition.OnboardingPhaseDetails);
            setIsValidRequisitionId(true);
        }
        else{
            if(requisitionError){
                if (requisitionError && requisitionError.data && (requisitionError.data.message === constants.ERROR.REQUISITION_NOT_FOUND_MESSAGE)) {
                    setIsValidRequisitionId(false);
                }
                else if (requisitionError && requisitionError.data && (requisitionError.data.message === constants.ERROR.UNAUTHORIZED_USER)) {
                    setShowUnauthorizedDialog(true);
                    setAuthType(AuthorizationType.SITE_BASED);
                }
                else if (requisitionError && requisitionError.data && (requisitionError.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                    setShowUnauthorizedDialog(true);
                    setAuthType(AuthorizationType.BRASS);
                }
                else{
                    props.snackbarShowMessage(
                        `${constants.ERROR.PREPOPULATE_ERROR}`,
                        "error"
                    );
                }
            }
            else{
                let data = (queryRequisition && queryRequisition.data) || {};
                let formDataL = { ...formData };
                formDataL.VendorId = data.vendorId ? data.vendorId : "";
                formDataL.LaborOrderId = data.laborOrderId ? data.laborOrderId : "";
                formDataL.JobId = data.jobId ? data.jobId : "";
                formDataL.RequisitionKey = data.requisitionKey ? data.requisitionKey : "";
                formDataL.RequisitionId = data.requisitionId ? data.requisitionId : "";
                formDataL.Site = data.site ? data.site : "";
                formDataL.JobRole = data.jobRole ? data.jobRole : "";
                formDataL.HeadCount = data.headCount ? data.headCount : "0";
                formDataL.JobType = data.jobType ? data.jobType : "";
                formDataL.VendorName = data.vendorName ? data.vendorName : "";
                formDataL.MinLeadPool = data.minLeadPool ? data.minLeadPool : "";
                formDataL.Balance = data.balance ? data.balance : "0";
                formDataL.RequisitionStatus = data.requisitionStatus ?( data.requisitionStatus === constants.STATUS.WIP_DB ? constants.STATUS.WIP_FRONTEND : data.requisitionStatus): "";
                formDataL.Reason = data.reason? data.reason : "";
                formDataL.Comment = data.comment? data.comment : "";
                formDataL.CreatedDate = data.createdDate ? data.createdDate : "";
                formDataL.WfsWatchersList = data.wfsWatchersList? data.wfsWatchersList : [];
                formDataL.VendorWatchersList = data.vendorWatchersList? data.vendorWatchersList : [];
                formDataL.CandidateCount = data.candidateCount ? data.candidateCount : "0";
                formDataL.BusinessLine = data.businessLine ? data.businessLine : "";
                formDataL.HiringWeek = data.hiringWeek ? data.hiringWeek : "";
                formDataL.RequisitionOldStatus = data.requisitionOldStatus ? data.requisitionOldStatus : null;
                formDataL.OnboardingPhaseDetails = data.onboardingPhaseDetails;
                formDataL.fillRate = data.fillRate ? data.fillRate : "0";
                formDataL.scheduleId = data.scheduleId ? data.scheduleId : "";
                formDataL.PhoMandatory = data.phoMandatory? data.phoMandatory: false;
                formDataL.country = data.country? data.country : "IN";
                if(data.tenure) {
                    formDataL.Tenure = data.tenure;
                }
                if(data.endDate) {
                    formDataL.LaborOrderEndDate = data.endDate;
                }
                setWatchersList(formDataL.WfsWatchersList);
                setFormData(formDataL);
                setPhases(data.onboardingPhaseDetails);
                setIsValidRequisitionId(true);
            }

        }
    }

    const populateComments = async (commentsList) => {
        let rowsData = []
        if (commentsList){
            commentsList.forEach(row => {
                rowsData.push({
                    createdDateTimestamp: row.createdDateTimestamp,
                    author: row.author,
                    date: row.date,
                    time: row.time,
                    comment: row.comment,
                    attachments: row.attachments,
                    vendorKamEmail: row.vendorKamEmail,
                    vendorName: row.vendorName,
                    status: row.status
                })
            })
        }
        setComments(rowsData)
    }

    useEffect(() => {
        let rows = queryComments &&  queryComments.data && queryComments.data.comments
        populateComments(rows)
    },[queryComments])

    const requestBody = {
        vendorId: formData.VendorId,
        requisitionId: formData.RequisitionId,
        laborOrderId: formData.LaborOrderId,
        jobId: (formData.JobId!==null && formData.JobId!=='NA')? formData.JobId : "",
        headCount: formData.HeadCount,
        jobType: formData.JobType,
        vendorName: formData.VendorName,
        site: formData.Site,
        jobRole: formData.JobRole,
        startDate: formData.StartDate,
        onboardingPhaseDetails: phases,
        minLeadPool: formData.MinLeadPool ? parseInt(formData.MinLeadPool) : 0,
        requisitionStatus: formData.RequisitionStatus === constants.STATUS.WIP_FRONTEND ? constants.STATUS.WIP_DB : formData.RequisitionStatus,
        reason: formData.Reason,
        comment:formData.Comment,
        wfsWatchersList: formData.WfsWatchersList,
        vendorWatchersList: formData.VendorWatchersList,
        businessLine: formData.BusinessLine,
        country: formData.country
    };

    const removeActivityConfirm = () => {
        setShowRemoveConfirmation(true);
    }

    const inactiveActivityConfirm = () =>{
        setShowInactiveConfirmation(true);
    }

    const activeActivityConfirm = () => {
        setShowActiveConfirmation(true);
    }

    const removeActivity = () => {
        setShowRemoveConfirmation(false)
        let body = {
            requisitionId: params.id,
            laborOrderId: formData.LaborOrderId,
            site: formData.Site
        };
        ActivityServices.removeRequisition(body).then((res) => {
            queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)
            queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
            props.snackbarShowMessage(`${constants.CRUD_SUCCESS_ALERTS.DELETED}`);
            window.setTimeout(() => {
                history.push(`/${constants.REQUISITIONS.ROUTE.MAIN}`);
            }, 1000)
        }).catch((error) => {
            if (error && error.data && (error.data.message === constants.ERROR.UNAUTHORIZED_USER)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.SITE_BASED);
            }
            else if (error && error.data && (error.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.BRASS);
            }
        })
    }

    const updateWatcher = (updatedWatcherList) => {
        requestBody.wfsWatchersList = updatedWatcherList;
        queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
        return ActivityServices.editRequisition(requestBody)
    }

    const markInactive = () => {
        setShowInactiveConfirmation(false);
        requestBody.requisitionStatus = "INACTIVE";
        ActivityServices.editRequisition(requestBody).then((res) => {
            if(res.data.status === "SUCCESS") {
                queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
                props.snackbarShowMessage (`${ constants.CRUD_SUCCESS_ALERTS.UPDATED }`);
                window.setTimeout (() => {
                    history.push (`/${ constants.REQUISITIONS.ROUTE.MAIN }`);
                }, 1000)
            }
            else if (res && res.data && (res.data.description === constants.ERROR.UNAUTHORIZED_USER)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.SITE_BASED);
            }
            else if (res && res.data && (res.data.description === constants.ERROR.UNAUTHORIZED_USER)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.BRASS);
            }
            else{
                props.snackbarShowMessage(
                    `${constants.ERROR.UPDATE_ERROR} ${res.data.description}`,
                    "error",
                    "5000",
                    res.data.description
                );
            }
        })
        .catch((res, error) => {
            props.snackbarShowMessage(
                `${constants.ERROR.CREATE_ERROR} ${res.data.message}`,
                "error",
                "5000",
                res.data.error
            );
        });
    }

    const markActive = () => {
        setShowInactiveConfirmation(false);
        requestBody.requisitionStatus = "OPEN";
        ActivityServices.editRequisition(requestBody).then((res) => {
            if(res.data.status === "SUCCESS") {
                queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
                props.snackbarShowMessage (`${ constants.CRUD_SUCCESS_ALERTS.UPDATED }`);
                window.setTimeout (() => {
                    history.push (`/${ constants.REQUISITIONS.ROUTE.MAIN }`);
                }, 1000)
            }
            else if (res && res.data && (res.data.description === constants.ERROR.UNAUTHORIZED_USER)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.SITE_BASED);
            }
            else if (res && res.data && (res.data.description === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.BRASS);
            }
            else{
                props.snackbarShowMessage(
                    `${constants.ERROR.UPDATE_ERROR} ${res.data.description}`,
                    "error",
                    "5000",
                    res.data.description
                );
            }
        }).catch((res, error) => {
            props.snackbarShowMessage(
                `${constants.ERROR.UPDATE_ERROR} ${res.data.description}`,
                "error",
                "5000",
                res.data.description
            );
        });
    }

    const editActivity = () => {
        history.push({pathname:`/${constants.REQUISITIONS.ROUTE.EDIT}/${params.LOid}&${params.id}`,
        state:{requisition: formData}});
    }

    const downloadCandidateData = () => {
        const body = {
            requisitionId : formData.RequisitionId,
            laborOrderId : formData.LaborOrderId,
            vendorId : formData.VendorName
        }
        ActivityServices.downloadCandidateData(body).then( async (res) => {
            let candidateData = res.data.candidates
            candidateData = candidateData.filter((candidate => candidate.rehireFlag !== constants.REHIRE_FLAG.NO_REHIRE));
            candidateData.forEach( cand => {
                const candId = cand.candidateId;
                cand.activeRequisitions = [...new Set(cand.activeRequisitions)].toString();
                cand.duplicateCandidateId = [...new Set(cand.duplicateCandidateId)]
                cand.duplicateCandidateId = cand.duplicateCandidateId.filter(function(value, index, arr){ 
                    return value !== candId;
                });
                cand.duplicateCandidateId = cand.duplicateCandidateId.toString();
            })


            const schema = LR_CANDIDATE_DOWNLOAD_SCHEMA[getUserCountry()];
            let xlsxData = [
                {
                sheet: `(${formData.RequisitionId})`,
                columns: schema,
                content: candidateData
                }
            ]
            
            let settings = {
                fileName: `(${formData.RequisitionId})candidateDataResponse`,
                extraLength: 3,
                writeOptions: {                width : 10                },
            }
            
            await xlsx(xlsxData, settings).wi
            props.snackbarShowMessage(`File successfully downloaded`);

        }).catch((error) => {
            props.snackbarShowMessage("No Data Avaialble","error")
        })


    }

    const markFulfilledActivityConfirm = () =>{
        setMarkFulfilledConfirmation(true);
    }

    const revokeFulfilledActivityConfirm = () =>{
        setRevokeFulfilledConfirmation(true);
    }

    const markAndRevokeFulfilledActivity = (status) => {
        setMarkFulfilledConfirmation(false);
        setRevokeFulfilledConfirmation(false);
        requestBody.requisitionStatus = status;
        ActivityServices.editRequisition(requestBody).then((res) => {
            if (res && res.data && (res.data.description === constants.ERROR.UNAUTHORIZED_USER)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.SITE_BASED);
            }
            else if (res && res.data && (res.data.description === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.BRASS);
            }
            else {
                queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
                props.snackbarShowMessage(`${constants.CRUD_SUCCESS_ALERTS.UPDATED}`);
                window.setTimeout(() => {
                    history.push(`/${constants.REQUISITIONS.ROUTE.MAIN}`);
                }, 1000)
            }
        })
        .catch((res, error) => {
            props.snackbarShowMessage(
                `${constants.ERROR.CREATE_ERROR} ${res.data.message}`,
                "error",
                "5000",
                res.data.error
            );
        });
    }

    const laborOrderActivity = () => {
                history.push(`/${constants.LABOR_ORDERS.ROUTE.VIEW}/${formData.LaborOrderId}`);
    }

    const viewCandidatesByRequisition = () => {
        history.push({
            pathname: `/${constants.CANDIDATE_MANAGEMENT.ROUTE.MAIN}`,
            state: {laborOrderId: params.LOid,requisitionId: params.id}
        });
    }

    const addComment = (comment) => {
        comment.vendorId = formData && formData.VendorId ? formData.VendorId : "";
        ActivityServices.createComment(comment).then((res) => {
            let commentsList = res && res.data && res.data.comments
            populateComments(commentsList)
        }).catch((error) => {
            props.snackbarShowMessage(
                `${constants.ERROR.COMMENT_ADD_ERROR}`,
                "error"
            );
        })
    }

    const editComment = (comment) => {
        ActivityServices.updateComment(comment).then((res) => {
            let commentsList = res && res.data && res.data.comments
            populateComments(commentsList)
        }).catch((error) => {
            props.snackbarShowMessage(
                `${constants.ERROR.COMMENT_UPDATE_ERROR}`,
                "error"
            );
        })
    }

    const deleteComment = (comment) => {
        ActivityServices.updateComment(comment).then((res) => {
            let commentsList = res && res.data && res.data.comments
            populateComments(commentsList)
        }).catch((error) => {
            props.snackbarShowMessage(
                `${constants.ERROR.COMMENT_DELETE_ERROR}`,
                "error"
            );
        })
    }


    const requisitionActiveActions = [
        {
            id:"1",
            Title:`${constants.BUTTON.EDIT + ' ' + constants.REQUISITIONS.TITLE_SINGULAR}`,
            Icon:EditIcon,
            onClick:editActivity
        },
        {
            id:"2",
            Title:constants.BUTTON.DELETE,
            Icon:DeleteIcon,
            onClick:removeActivityConfirm
        },
        {
            id:"3",
            Title:constants.BUTTON.INACTIVE,
            Icon:SyncDisabled,
            onClick:inactiveActivityConfirm
        },
        {
            id: "4",
            Title: constants.BUTTON.VIEW_CANDIDATE,
            Icon: FilterListIcon,
            onClick: viewCandidatesByRequisition
        },
    ]

    const requisitionWorkInProgressActions = [
        {
            id:"1",
            Title:`${constants.BUTTON.EDIT + ' ' + constants.REQUISITIONS.TITLE_SINGULAR}`,
            Icon:EditIcon,
            onClick:editActivity
        },
        {
            id:"2",
            Title:constants.BUTTON.DELETE,
            Icon:DeleteIcon,
            onClick:removeActivityConfirm
        },
        {
            id:"3",
            Title:constants.BUTTON.INACTIVE,
            Icon:SyncDisabled,
            onClick:inactiveActivityConfirm
        },
        {
            id:"4",
            Title:constants.BUTTON.DOWNLOAD_CANDIDATE_DATA,
            Icon:ArrowDownward,
            onClick:downloadCandidateData
        },
        {   id:"5",
            Title:constants.BUTTON.VIEW_CANDIDATE ,
            Icon: FilterListIcon,
            onClick: viewCandidatesByRequisition
        }

    ]


    //Fulfilled option would be available when last onboarding phase Need By Date <= Today's Date
    if(phases && phases.length !== 0 && (new Date(phases[phases.length - 1].startDate+" 00:00") <= new Date())) {
        requisitionActiveActions.push({
            id: "5",
            Title: constants.BUTTON.MARK_FULFILLED,
            Icon: CheckCircleSharp,
            onClick: markFulfilledActivityConfirm
        });

        requisitionWorkInProgressActions.push({
            id: "6",
            Title: constants.BUTTON.MARK_FULFILLED,
            Icon: CheckCircleSharp,
            onClick: markFulfilledActivityConfirm
        });
    }

    const requisitionInactiveActions = [
        {
            id:"1",
            Title:constants.BUTTON.DELETE,
            Icon:DeleteIcon,
            onClick:removeActivityConfirm
        },
        {
            id:"2",
            Title:constants.BUTTON.ACTIVE,
            Icon:SyncDisabled,
            onClick:activeActivityConfirm
        },
        {
            id:"3",
            Title:constants.BUTTON.DOWNLOAD_CANDIDATE_DATA,
            Icon:ArrowDownward,
            onClick:downloadCandidateData
        }
    ]

    let requisitionFulfilledActions = [
        {
            id:"1",
            Title:constants.BUTTON.DELETE,
            Icon:DeleteIcon,
            onClick:removeActivityConfirm
        },
        {
            id:"2",
            Title:constants.BUTTON.INACTIVE,
            Icon:SyncDisabled,
            onClick:inactiveActivityConfirm
        },
        {
            id: "3",
            Title: constants.BUTTON.VIEW_CANDIDATE,
            Icon: FilterListIcon,
            onClick: viewCandidatesByRequisition
        },
        {
            id:"4",
            Title:constants.BUTTON.DOWNLOAD_CANDIDATE_DATA,
            Icon:ArrowDownward,
            onClick:downloadCandidateData
        }
    ]

    if(formData.RequisitionOldStatus !== null){
        requisitionFulfilledActions = requisitionFulfilledActions.concat(
            [{
                id:"3",
                Title:constants.BUTTON.REVOKE_FULFILLED,
                Icon:UndoIcon,
                onClick: revokeFulfilledActivityConfirm
            }]
        )
    }


    const getRequisitionActionsByStatus = (RequisitionStatus) => {
        if(formData.RequisitionStatus === "INACTIVE"){
            return <Demo option={requisitionInactiveActions}/>
        }else if(formData.RequisitionStatus === "Work In Progress"){
            return <Demo option={requisitionWorkInProgressActions}/>
        } else if(formData.RequisitionStatus === constants.REQUISITIONS.STATUS.FULFILLED){
            return <Demo option={requisitionFulfilledActions}/>
        } else {
            return <Demo option={requisitionActiveActions}/>
        }
    }

    const getJobUrl = () => {
        if(formData.JobId && formData.JobId.startsWith("JOB-IN-")){
            let urlVendorId = formData.VendorId;
            if (urlVendorId === 'S&IB') {
                urlVendorId = 'SIB' //Special case due to '&' character, agreed with Career Site Team
            }
            return `${sessionStorage.getItem('careerSiteURL')}/app?vendorid=${urlVendorId}#/jobDetail?jobId=${formData.JobId}&locale=en-IN&vendorid=${urlVendorId}`
        }
        else{
            return `${sessionStorage.getItem('careerPortalURL')}/${formData.JobId}`
        }
    }

    const jobUrl = useMemo(() => getJobUrl(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formData.JobId])

    return (
        !isValidRequisitionId ? <PageNotFound/> :
            <div className='amz_content_view view_page_module mb-100 view_page'>
                <Box sx={{ width: '100%' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} className={classes.tabs} aria-label="requisitions tabs">
                        <LinkTab label={constants.REQUISITIONS.TITLE_DETAILS} />
                        <LinkTab label={constants.AUDIT_TRAIL.TITLE} />
                    </Tabs>
                    <Divider id = "tabsDivider" style={{width:"100%",marginTop:"-0.1rem", height:"0", border: "1px solid #868C96"}}/>
                </Box>
                <TabPanel value={tabValue} index={0}>
            <Container id="viewRequisition" maxWidth="lg">
                <Grid container direction="row" justify="space-around" alignItems="center">
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className={classes.root}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                <Link className="primary_color" onClick={() => (history.push(`/${constants.REQUISITIONS.ROUTE.MAIN}`))}>
                                    {constants.REQUISITIONS.ROUTE_GRID}
                                </Link>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-center d-flex">
                                    <FormPageTItle Title={formData.RequisitionId} />
                                </Grid>
                            </Breadcrumbs>
                        </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        {formData.RequisitionStatus !== "DELETED" &&
                        <div className="form_page_actions">
                            <Watcher  watchersList={watchersList} setWatchersList={setWatchersList}
                                      updateWatcher={updateWatcher} isUpdateRequired={true} />
                            <>
                                {getRequisitionActionsByStatus(formData.RequisitionStatus)}
                            </>
                        </div>
                        }
                    </Grid>

                    {requisitionLoading ? <CircularProgress/> :
                    <Grid container alignItems="top" className="form_group_list">

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item"
                                  style={{paddingBottom: "0.7rem"}}>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Labor order:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <ButtonSecondary Title={formData.LaborOrderId}
                                                     onClick={laborOrderActivity}></ButtonSecondary>
                                </Grid>
                            </Grid>
                            {formData.JobId !== "NA" && (formData.RequisitionStatus === "Work In Progress" || formData.RequisitionStatus === "ACCEPTED" || formData.RequisitionStatus === "FULFILLED") && !isMenaUser() &&
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <InputLabel Title="Job Id:"/>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        {formData.JobId === "" ? <FormFieldView isDisabled={true}
                                                                                Content={constants.ERROR.JOB_UNDER_PROCESS}/> :
                                            <Link
                                                href={jobUrl}
                                                target="_blank"
                                                style={{color: "#007486", fontSize: "1.2rem", marginRight: "5px"}}
                                                color="#0073e6"><u>{formData.JobId}</u>
                                            </Link>}
                                    </Grid>
                                </Grid>
                            }
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Site:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.Site}/>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Job Role:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.JobRole}/>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Balance head count:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.Balance}/>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Status:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.RequisitionStatus}/>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{marginTop: "1rem"}}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Requisition:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.RequisitionId}/>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Type of contract:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.Tenure ? "Fixed Tenure" : "Flexible Tenure"}/>
                                </Grid>
                            </Grid>
                            {formData.Tenure && <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Tenure(days):"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.Tenure}/>
                                </Grid>
                            </Grid>}
                            {formData.LaborOrderEndDate &&
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <InputLabel Title="End date:"/>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <FormFieldView Content={formData.LaborOrderEndDate}/>
                                    </Grid>
                                </Grid>}
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Created Date:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.CreatedDate}/>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Hiring Week:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.HiringWeek}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                        <FormPageSubHeading Title={constants.FORM_DETAILS.DETAILS} />
                    </Grid>

                    {requisitionLoading ? <CircularProgress/> :
                    <Grid container alignItems="top" className="form_group_list">

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Vendor:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.VendorName}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Job Type:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.JobType}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Head count:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.HeadCount}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        {CANDIDATE_BALANCE_LIST.includes(formData.RequisitionStatus) &&
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <InputLabel Title="Candidate Pool:"/>
                                        <InfoHelpIcon
                                            FieldToolTip="Total number of candidates uploaded by the Vendor (includes duplicates)"
                                            IconClassName={<InfoOutlinedIcon/>}
                                            Title="Type"
                                        />
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <FormFieldView Content={formData.CandidateCount}/>
                                    </Grid>
                                </Grid>
                            </Grid>}

                        {formData.MinLeadPool && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Recommended Lead Pool:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.MinLeadPool}/>
                                </Grid>
                            </Grid>
                        </Grid>}

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Fill Rate:" />
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.fillRate + "/" +formData.HeadCount} />
                                </Grid>
                            </Grid>
                        </Grid>


                        {phases && phases.map((phase, index) => !isMenaUser() ? <ViewOnboardingPhase key={index} Phase={phase}
                                                                                     Index={index + 1}
                                                                                     length={phases.length}/> : <ViewMenaOnboardingPhase key={index} Phase={phase}
                                                                                                                                                  Index={index + 1}
                                                                                                                                                  length={phases.length}/>)}
                    </Grid>}

                    { formData.RequisitionStatus === "DECLINED" &&
                        <>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                            <FormPageSubHeading Title={constants.FORM_DETAILS.REASON}/>
                        </Grid>

                        <Grid item xl={9} lg={9} md={9} sm={9} xs={12}>
                            <Grid container alignItems="flex-start" className="form_group_item form_group_item_view form_textarea">
                                <InputLabel Title="Reason:" />
                                <FormFieldView Content={formData.Reason} />
                            </Grid>
                        </Grid>

                        <Grid item xl={9} lg={9} md={9} sm={9} xs={12}>
                            <Grid container alignItems="flex-start" className="form_group_item form_group_item_view form_textarea">
                                <InputLabel Title="Comments:" />
                                <FormFieldView Content={formData.Comment} />
                            </Grid>
                        </Grid>
                        </>
                    }

                </Grid>
                <CommentsLog isLoading={commentsLoading} comments={comments} onEditComment={editComment} onDeleteComment={deleteComment} onAddComment={addComment} parentID={params.id} LOID={formData.LaborOrderId} parentStatus={formData.RequisitionStatus} country={formData.country}/>
            </Container>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                        <AuditLogCards config={{pk:params.LOid,sk:params.id,type:"Requisition"}}/>
                </TabPanel>
            {showRemoveConfirmation &&
            <FormActionAlertDialog Message="Deleting this requisition will automatically delete all the references against it. Do you want to proceed?" handleYes={removeActivity} handleNo={() => { setShowRemoveConfirmation(false) }} />
            }
            {showInactiveConfirmation &&
                <FormActionAlertDialog Message="Marking inactive this requisition will automatically inactivate all the references against it. Do you want to proceed?" handleYes={markInactive} handleNo={() => { setShowInactiveConfirmation(false) }} />
            }
            {showActiveConfirmation &&
                <FormActionAlertDialog Message="Marking active this requisition will automatically activate all the references against it. Do you want to proceed?" handleYes={markActive} handleNo={() => { setShowActiveConfirmation(false) }} />
            }
            {showMarkFulfilledConfirmation &&
                 <FormActionAlertDialog Message="Marking this requisition as fulfilled. Do you want to proceed?" handleYes={()=>markAndRevokeFulfilledActivity(constants.REQUISITIONS.STATUS.FULFILLED)} handleNo={() => { setMarkFulfilledConfirmation(false) }} />
            }
            {showRevokeFulfilledConfirmation &&
                 <FormActionAlertDialog Message="Revoking fulfilled status for this requisition. Do you want to proceed?" handleYes={()=>markAndRevokeFulfilledActivity(formData.RequisitionOldStatus)} handleNo={() => { setRevokeFulfilledConfirmation(false) }} />
            }
                {showUnauthorizedDialog && (
                    <UnauthorizedAccessDialog
                        handleCancel={() => {
                            setShowUnauthorizedDialog(false);
                        }}
                        authorizationType={authType}
                        snackbarShowMessage={props.snackbarShowMessage}
                    />
                )}
        </div>
    )
}

export default withAlertSnackBar(ViewRequisition);