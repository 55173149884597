import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {Breadcrumbs, Container, FormControl, FormControlLabel, Grid, Link, makeStyles, Radio, RadioGroup, Typography} from "@material-ui/core";
import ButtonPrimary from "../../../components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../components/ButtonSecondary/ButtonSecondary";
import FormPageTItle from "../../../components/FormPage/FormPageTItle";
import FormPageSubHeading from "../../../components/FormPage/FormPageSubHeading";
import InputText from "../../../components/FormPage/InputText";
import InputLabel from "../../../components/FormPage/InputLabel";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SelectSingle from "../../../components/FormPage/SelectSingle";
import FormInputDatePicker from "../../../components/FormPage/FormInputDatePicker";
import constants, {AuthorizationType} from "../../../Utils/Constants";
import {ActivityServices} from "../../../Services/activities";
import Formfieldstar from "../../../components/FormPage/FormFieldStar";
import {
    FormNonZerovalidationerror,
    FormPastDatevalidationerror,
    Formvalidationerror
} from "../../../components/FormPage/FormValidationError";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import {withAlertSnackBar} from "../../../HOComponents/AlertSnackBarHOC";
import FormFieldView from "../../../components/FormPage/FormFieldView";
import {disablePastDate} from "../../../Utils/DateUtil";
import {testPattern} from "../../../Utils/PatternUtil";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import InfoHelpIcon from "../../../components/FormPage/InfoHelpIcon";
import Watcher from "../../../components/DropdownMenu/WatchersDropdownMenu";
import PageNotFound from "../../PageNotFound/PageNotFound";
import FormActionAlertDialog from "../../../components/FormPage/FormActionAlertDialog";
import UnauthorizedAccessDialog from "../../PageNotFound/UnauthorizedAccessDialog";
import {useGetAllSites} from "../../../ReactQuery/hooks/useGetAllSites";
import { useLocation} from "react-router-dom";
import { useQueryClient} from 'react-query'
import Constants from "../../../Utils/Constants";
import {useGetAuthorizedSites} from "../../../ReactQuery/hooks/useGetAuthorizedSites";
import {useGetLaborOrder} from "../../../ReactQuery/hooks/useGetLaborOrder";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@mui/material/Switch";
import CreateRLCModal from "../../../components/Modals/CreateRLCModal";
import {useGetRelatedRequisitions} from "../../../ReactQuery/hooks/useGetRelatedRequisitions";
import {isMenaUser} from "../../../Utils/RegionUtil";
import MultiAutoComplete from "../../../components/FormPage/MultiAutoComplete";

function CreateLaborOrder(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            "& > * + *": {
                marginTop: theme.spacing(2),
            },
        },
    }));

    let history = useHistory();
    const classes = useStyles();
    const params = useParams();
    const location = useLocation();
    const [globalSites, setGlobalSites] = useState([]);
    const [formData, setFormData] = useState({GeneralCount: "0", WomenCount: "0", DisabilityCount: "0", TransgenderCount: "0", ContractType: "fixed"});
    const [businessLines, setBusinessLines] = useState(JSON.parse(JSON.stringify(isMenaUser() ? constants.ARRAYS.MENA_BUSINESS_LINES : constants.ARRAYS.IN_BUSINESS_LINES)));
    const [jobRoles, setJobRoles] = useState(JSON.parse(JSON.stringify(isMenaUser() ? constants.ARRAYS.MENA_JOB_ROLES : constants.ARRAYS.IN_JOB_ROLES)));
    const [redLevelChange,setRedLevelChange] = useState(JSON.parse(JSON.stringify(constants.ARRAYS.RLC_OPTIONS)));
    const [shifts,setShifts] = useState(JSON.parse(JSON.stringify(constants.ARRAYS.MENA_SHIFTS)));
    const [departments,setDepartments] = useState(JSON.parse(JSON.stringify(constants.ARRAYS.MENA_DEPARTMENTS)));
    const [alpsCodes,setAlpsCodes] = useState(JSON.parse(JSON.stringify(constants.ARRAYS.MENA_ALPS_CODES)));
    const [daysOff,setDaysOff] = useState(JSON.parse(JSON.stringify(constants.ARRAYS.DAYS_OFF)));
    const [rlcApprovalId,setRlcApprovalId] = useState({isRequired:false, approvalId: ""});
    const [sites, setSites] = useState([]);
    const [watchersList, setWatchersList] = React.useState([]);
    const [isWatcherDisabled, setIsWatcherDisabled] = React.useState(true);
    const [isValidLaborOrderId,setIsValidLaborOrderId] = React.useState(true);
    const [laborOrderExistForSameHiringWeek,setLaborOrderExistForSameHiringWeek] = React.useState(false);
    const [laborOrderBody,setLaborOrderBody] = React.useState({});
    const [duplicateLOs, setDuplicateLOs] = React.useState([]);
    const [showUnauthorizedDialog, setShowUnauthorizedDialog] = React.useState(false);
    const [authType, setAuthType] = React.useState("");
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isSitesDisabled, setIsSitesDisabled] = useState(true);
    const [isNotificationNeeded, setIsNotificationNeeded] = useState(true);
    const [showCreateRLCFlyout, setShowCreateRLCFlyout] = useState(false);
    const [isRLCFromSwayam,setIsRLCFromSwayam] = useState(JSON.parse(JSON.stringify(constants.ARRAYS.IS_RLC)));
    const [isRoleEditable, setIsRoleEditable] = useState(true)
    const queryClient = useQueryClient();

    const {data: querySites, isFetching: sitesLoading} = useGetAllSites()
    const {data: queryAuthorizedSites, isFetching: authorizedSitesLoading} = useGetAuthorizedSites()
    const {data: queryLaborOrder, isFetching: laborOrderLoading, error: laborOrderError} = useGetLaborOrder(params.id, !!(params.id) && !(location.state && location.state.laborOrder))
    const {data: relatedRequisitions, isLoading: relatedRequisitionsLoading} = useGetRelatedRequisitions(params.id ? {laborOrderId: params.id,alias: JSON.parse(sessionStorage.getItem('session_user')).id,
        filterBy: "LR",} : null)

    useEffect(() => {
        if(querySites && queryAuthorizedSites && queryAuthorizedSites.data){
            let authorizedCountries = sessionStorage.getItem("authorizedCountries").split(',')
            let querySitesList = (querySites && querySites.data) || [];
            let authorizedSites = (queryAuthorizedSites && queryAuthorizedSites.data.authorizedSites) || []
            let sitesL = querySitesList.map((m) => ({
                id: m.siteId,
                is_selected: false,
                name: m.siteId ? m.siteId : "",
                businessLine: m.businessLine ? m.businessLine : "",
                wfsTeam: m.agencyName? m.agencyName : "",
                country: m.homeCountryGroup
            }));
            if(!authorizedSites.includes("ADMIN") && !authorizedSites.includes("ADMIN_AE") &&
                !authorizedSites.includes("ADMIN_SA") && !authorizedSites.includes("ADMIN_EG"))
            {
                sitesL = sitesL.filter((site) => authorizedSites.some((authSite) => (authSite === site.id)))
            }
            sitesL = sitesL.filter((site) => authorizedCountries.some((country) => (country === site.country)))
            setSites(sitesL);
            setGlobalSites(sitesL)
        }
    },[querySites, queryAuthorizedSites])

    useEffect(() => {
        if(relatedRequisitions && relatedRequisitions.data && relatedRequisitions.data.RequisitionInfos){
            relatedRequisitions.data.RequisitionInfos.forEach((requisition) => {
                if(requisition.requisitionStatus === constants.STATUS.WIP_DB || requisition.requisitionStatus === constants.STATUS.ACCEPTED || requisition.requisitionStatus === constants.STATUS.FULFILLED){
                    setIsRoleEditable(false)
                }
            })
        }
    },[relatedRequisitions])

    useEffect(() => {
        if (params.id) {
            if(globalSites.length>0)
            prepopulate();
            setIsWatcherDisabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params,globalSites, queryLaborOrder, laborOrderError]);

    window.history.replaceState({}, document.title)

    const prepopulate = async () => {
        if(location.state && location.state.laborOrder){
            let formDataL = { ...location.state.laborOrder};
            populateFields(formDataL)
            setIsValidLaborOrderId(true);
        }
        else{
            if(laborOrderError){
                if (laborOrderError && laborOrderError.data && (laborOrderError.data.message === constants.ERROR.UNAUTHORIZED_USER)) {
                    setShowUnauthorizedDialog(true);
                    setAuthType(AuthorizationType.SITE_BASED);
                }
                else if (laborOrderError && laborOrderError.data && (laborOrderError.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                    setShowUnauthorizedDialog(true);
                    setAuthType(AuthorizationType.BRASS);
                }
                else if (laborOrderError && laborOrderError.data && (laborOrderError.data.message === constants.ERROR.LABOR_ORDER_NOT_FOUND_MESSAGE)) {
                    setIsValidLaborOrderId(false);
                } else {
                    props.snackbarShowMessage(
                        `${constants.ERROR.PREPOPULATE_ERROR}`,
                        "error");
                }
                }
            else{
                let data = (queryLaborOrder && queryLaborOrder.data) || {};
                let formDataL = { ...formData };
                formDataL.LaborOrderId = data.laborOrderId?data.laborOrderId: "";
                formDataL.HeadCount = data.headCount ? data.headCount : "0";
                formDataL.GeneralCount = data.generalCount ? data.generalCount : "0";
                formDataL.WomenCount = data.womenCount ? data.womenCount : "0";
                formDataL.DisabilityCount = data.disabilityCount ? data.disabilityCount : "0";
                formDataL.TransgenderCount = data.transgenderCount ? data.transgenderCount : "0";
                formDataL.LaborOrderStartDate = data.startDate ? data.startDate : "";
                formDataL.CreatedDate = data.createdDate ? data.createdDate : "";
                formDataL.Balance = data.balance ? data.balance : "0";
                formDataL.LaborOrderStatus = data.laborOrderStatus ? data.laborOrderStatus : ""
                formDataL.wfsWatchersList = data.wfsWatchersList ? data.wfsWatchersList : [];
                formDataL.Site = data.site ? data.site : "";
                formDataL.BusinessLine = data.businessLine ? data.businessLine : "";
                formDataL.HiringWeek = data.hiringWeek ? data.hiringWeek : "";
                formDataL.RlcApprovalId = data.rlcApprovalId ? data.rlcApprovalId : "";
                formDataL.Tenure = data.tenure;
                formDataL.LaborOrderEndDate = data.endDate;
                formDataL.JobRole = data.jobRole;
                formDataL.Shift = data.shift ? data.shift : "";
                formDataL.CostCenter = data.costCenter ? data.costCenter : "";
                formDataL.Manager = data.hiringManager ? data.hiringManager : "";
                formDataL.Department = data.department ? data.department : "";
                formDataL.AlpsCode = data.alpsCode ? data.alpsCode : "";
                formDataL.DaysOff = data.daysOff ? data.daysOff : "";
                formDataL.RedLevelChange = data.redLevelChange;
                populateFields(formDataL)
                setIsValidLaborOrderId(true);
            }
        }

    };

    const populateFields = async(data) => {
        if(data.Tenure) {
            data.ContractType = "fixed";
        }
        if(data.LaborOrderEndDate) {
            data.ContractType = "flexible";
        }
        prepopulateWatcher(data.Site, data.wfsWatchersList);
        setFormData(data)
        if (data.BusinessLine) {
            await prepopulateBusinessLines(data.BusinessLine);
            if (data.Site) {
                prepopulateSites(data.Site,data.BusinessLine);
            }
        }
        if (data.JobRole) {
            prepopulatejobRoles(data.JobRole);
        }
        if(data.Shift) {
            prepopulateShifts(data.Shift)
        }
        if(data.Department) {
            prepopulateDepartments(data.Department)
        }
        if(data.AlpsCode) {
            prepopulateAlpsCodes(data.AlpsCode)
        }
        if(data.DaysOff) {
            prepopulateDaysOff(data.DaysOff)
        }
        prepopulateRLC(data.RedLevelChange?data.RedLevelChange:"NO");
    }

    const prepopulateWatcher = (currentSite, currentWatchers) => {
        let sitesL = [...sites];
        sitesL.forEach( (site) => {
            if (site.id === currentSite) {
                const defaultWatcher = JSON.parse(sessionStorage.getItem('session_user')).id +
                    constants.WATCHER.EMAIL_SUFFIX;
                const siteWatchersList = site.wfsTeam.split(",")
                    .map((wfs)=>{
                        return wfs.trim() + constants.WATCHER.EMAIL_SUFFIX;}) //Filtering the default and already present values
                    .filter((wfs) => wfs !== "None@amazon.com" && wfs !== defaultWatcher);
                let newWatchersList = [...currentWatchers, defaultWatcher,...siteWatchersList];
                //Getting only the unique watchers
                newWatchersList = newWatchersList.filter((watcher, pos) => newWatchersList.indexOf(watcher) === pos)
                setWatchersList(newWatchersList);
            }
        });
    }

    const createLaborOrder = async (body) => {
        await ActivityServices.addActivity(body)
            .then((res) => {
                queryClient.invalidateQueries(Constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)
                props.snackbarShowMessage(
                    `${constants.CRUD_SUCCESS_ALERTS.CREATED}`
                );
                window.setTimeout(() => {
                    history.push(
                        `/${constants.LABOR_ORDERS.ROUTE.VIEW}/${res.data.laborOrderId}`
                    );
                }, 1000);
            })
            .catch((res, error) => {
                if (res && res.data && (res.data.message === constants.ERROR.UNAUTHORIZED_USER)) {
                    setShowUnauthorizedDialog(true);
                    setAuthType(AuthorizationType.SITE_BASED);
                }
                else if (res && res.data && (res.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                    setShowUnauthorizedDialog(true);
                    setAuthType(AuthorizationType.BRASS);
                }
                else {
                    setIsFormSubmitted(false);
                    props.snackbarShowMessage(
                        `${constants.ERROR.CREATE_ERROR} ${res.data.message}`,
                        "error",
                        "5000",
                        res.data.error
                    );
                }
            });
    }

    const updateLaborOrder = async (body) => {
       await ActivityServices.editActivity(body)
            .then((res) => {
                if(res.data.status === 'FAILED'){
                    props.snackbarShowMessage(`${res.data.description}`,"error");
                }else{
                    queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)
                    queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
                    props.snackbarShowMessage(
                        `${constants.CRUD_SUCCESS_ALERTS.UPDATED}`
                    );
                    window.setTimeout(() => {
                        history.push(`/${constants.LABOR_ORDERS.ROUTE.VIEW}/${params.id}`);
                    }, 1000);
                }

            })
            .catch((res, error) => {
                if (res && res.data && (res.data.message === constants.ERROR.UNAUTHORIZED_USER)) {
                    setShowUnauthorizedDialog(true);
                    setAuthType(AuthorizationType.SITE_BASED);
                }
                else if (res && res.data && (res.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                    setShowUnauthorizedDialog(true);
                    setAuthType(AuthorizationType.BRASS);
                }
                else {
                    setIsFormSubmitted(false);
                    props.snackbarShowMessage(
                        `${constants.ERROR.UPDATE_ERROR} ${res.data.message}`,
                        "error",
                        "5000",
                        res.data.message
                    );
                }
            });
    }

    const validateMenaForm = () => {
        let isFormValid = true;
        if (
            !formData.HeadCount  ||
            parseInt(formData.HeadCount) < 1 ||
            !formData.GeneralCount ||
            !formData.WomenCount ||
            !formData.DisabilityCount ||
            !formData.TransgenderCount ||
            !businessLines.find((t) => t.is_selected) ||
            !sites.find((t) => t.is_selected) ||
            !jobRoles.find((t) => t.is_selected) ||
            !shifts.find((shift) => shift.is_selected) ||
            !daysOff.find((day) => day.is_selected) ||
            !formData.CostCenter ||
            !formData.Manager ||
            !alpsCodes.find((alpsCode) => alpsCode.is_selected) ||
            !formData.LaborOrderStartDate ||
            (formData.ContractType === "fixed" && (!formData.Tenure || parseInt(formData.Tenure) < 1)) ||
            new Date(formData.LaborOrderStartDate)<new Date(disablePastDate(new Date(), -1)) ||
            (formData.ContractType === "flexible" && (!formData.LaborOrderEndDate || (new Date(formData.LaborOrderEndDate) < new Date() ))) ||
            (rlcApprovalId.isRequired && (!formData.RlcApprovalId || /^\s*$/.test(formData.RlcApprovalId)))

        )
            isFormValid = false;

        return isFormValid;
    };

    const validateForm = () => {
        let isFormValid = true;
        if (
            !formData.HeadCount  ||
            parseInt(formData.HeadCount) < 1 ||
            !formData.GeneralCount ||
            !formData.WomenCount ||
            !formData.DisabilityCount ||
            !formData.TransgenderCount ||
            !businessLines.find((t) => t.is_selected) ||
            !sites.find((t) => t.is_selected) ||
            !jobRoles.find((t) => t.is_selected) ||
            !formData.LaborOrderStartDate ||
            (formData.ContractType === "fixed" && (!formData.Tenure || parseInt(formData.Tenure) < 1)) ||
            new Date(formData.LaborOrderStartDate)<new Date(disablePastDate(new Date(), -1)) ||
            (formData.ContractType === "flexible" && (!formData.LaborOrderEndDate || (new Date(formData.LaborOrderEndDate) < new Date() ))) ||
            (rlcApprovalId.isRequired && (!formData.RlcApprovalId || /^\s*$/.test(formData.RlcApprovalId)))

        )
            isFormValid = false;

        return isFormValid;
    };

    const validateCount = () => {
        let isCountValid = true;
        if(parseInt(formData.HeadCount) !== parseInt(formData.GeneralCount) + parseInt(formData.WomenCount) + parseInt(formData.DisabilityCount) + parseInt(formData.TransgenderCount)) {
            isCountValid = false;
        }
        return isCountValid;
    }

    const formSubmit = async () => {
        setIsFormSubmitted(true);
        if (isMenaUser() ? validateMenaForm() : validateForm()) {
            if (validateCount()) {
                let selectedDaysOff = daysOff.filter((day) => day.is_selected)
                let body = {
                    headCount: formData.HeadCount ? parseInt(formData.HeadCount) : 0,
                    businessLine: businessLines.find((s) => s.is_selected).name,
                    jobRole: jobRoles.find((s) => s.is_selected).name,
                    site: sites.find((s) => s.is_selected).name,
                    startDate: formData.LaborOrderStartDate ? formData.LaborOrderStartDate : "",
                    generalCount: formData.GeneralCount ? parseInt(formData.GeneralCount) : 0,
                    womenCount: formData.WomenCount ? parseInt(formData.WomenCount) : 0,
                    disabilityCount: formData.DisabilityCount ? parseInt(formData.DisabilityCount) : 0,
                    transgenderCount: formData.TransgenderCount ? parseInt(formData.TransgenderCount) : 0,
                    wfsWatchersList: watchersList,
                    notificationNeeded: params.id ? isNotificationNeeded : true,
                    shift: isMenaUser() ? shifts.find((shift) => shift.is_selected).name : "",
                    daysOff: isMenaUser() ? selectedDaysOff ? selectedDaysOff.map((day) => day.name).join(",") : "" : "",
                    costCenter: formData.CostCenter,
                    hiringManager: formData.Manager,
                    department: isMenaUser() ? departments.find((department) => department.is_selected)?.name : "",
                    alpsCode: isMenaUser() ? alpsCodes.find((alpsCode) => alpsCode.is_selected).name : "",
                    country: sites.find((s) => s.is_selected).country
                };
                if(isRLCFromSwayam.find((s) => s.is_selected)){
                    body.redLevelChange = redLevelChange.find((s) => s.is_selected).name
                } else{
                    body.redLevelChange = "NO"
                }
                if (rlcApprovalId.isRequired){
                    body.rlcApprovalId = formData.RlcApprovalId;
                }
                if (formData.ContractType === "fixed") {
                    body.tenure = formData.Tenure ? parseInt(formData.Tenure) : 0
                } else {
                    body.endDate = formData.LaborOrderEndDate ? formData.LaborOrderEndDate : ""
                }
                if (params.id) {
                    body.laborOrderId = params.id;
                    body.createdDate = formData.CreatedDate;
                    body.laborOrderStatus = formData.LaborOrderStatus;
                    await ActivityServices.validateLaborOrder(body).then((response) => {
                        let data = response && response.data;
                        if (data.isLaborOrderExist) {
                            setLaborOrderBody(body);
                            setDuplicateLOs(data.laborOrderIds)
                            setLaborOrderExistForSameHiringWeek(true);
                        } else {
                            updateLaborOrder(body);
                        }
                    })

                } else {
                    await ActivityServices.validateLaborOrder(body).then((response) => {
                        let data = response && response.data;
                        if (data.isLaborOrderExist) {
                            setLaborOrderBody(body);
                            setDuplicateLOs(data.laborOrderIds);
                            setLaborOrderExistForSameHiringWeek(true);
                        } else {
                            createLaborOrder(body);
                        }
                    }).catch((res, error) => {
                        if (res && res.data && (res.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                            setShowUnauthorizedDialog(true);
                            setAuthType(AuthorizationType.BRASS);
                    }});
                }
            } else {
                setIsFormSubmitted(false);
                props.snackbarShowMessage(
                    `${constants.ERROR.CREATE_ERROR} ${constants.ERROR.COUNT_ERROR}`,
                    "error",
                    "5000",
                    constants.ERROR.COUNT_ERROR
                );
            }
        }
    };

    const cancelActivity = () => {
        if (params.id) {
            history.push({pathname:`/${constants.LABOR_ORDERS.ROUTE.VIEW}/${params.id}`});
        } else if (!params.id && !params.relatedID && !params.relatedName) {
            history.push(`/${constants.LABOR_ORDERS.ROUTE.MAIN}`);
        } else if (params.relatedID && params.relatedName) {
            history.goBack();
        }
    };

    const jobRolesChangeHandler = (event) => {
        setIsFormSubmitted(false);
        let value = event.target.value;
        let jobRolesL = [...jobRoles];
        jobRolesL.forEach((o) => {
            o.is_selected = value && o.id === value;
        });
        setJobRoles(jobRolesL);
    };

    const redLevelChangeHandler = (event) => {
        setIsFormSubmitted(false);
        let value = event.target.value;
        let redLevelChangeL = [...redLevelChange];
        let rlcApprovalIdL = {...rlcApprovalId};
        rlcApprovalIdL.isRequired = value !== "0";  // 0 is the value for no option selected;
        redLevelChangeL.forEach((o) => {
            o.is_selected = value && o.id === value;
        });
        setRedLevelChange(redLevelChangeL);
        setRlcApprovalId(rlcApprovalIdL);
    };

    const isRLCChangeHandler = (event) =>{
        let isRLCFromSwayamL = [...isRLCFromSwayam];
        let value = event.target.value;
        isRLCFromSwayamL.forEach((obj)=>{
            obj.is_selected = value && obj.id === value;
        })
        if(isRLCFromSwayamL.filter(obj => obj.is_selected===true).length===0){
            let rlcApprovalIdL = {...rlcApprovalId};
            let redLevelChangeL = [...redLevelChange];
            redLevelChangeL.forEach((o) => {
                o.is_selected = false;
            });
            rlcApprovalIdL.isRequired = false;
            setRlcApprovalId(rlcApprovalIdL);
            setRedLevelChange(redLevelChangeL);
        }
        setIsRLCFromSwayam(isRLCFromSwayamL);
        if(value==="Create RLC approval")
            setShowCreateRLCFlyout(true);
    }

    const resetRedLevelChangeHandler = () => {
        let isRLCFromSwayamL = [...isRLCFromSwayam];
        isRLCFromSwayamL.forEach((o) => {
            o.is_selected = false;
        });
        setIsRLCFromSwayam(isRLCFromSwayamL);
        setShowCreateRLCFlyout(false);
    }

    const prepopulatejobRoles = (value) => {
        let jobRolesL = [...jobRoles];
        jobRolesL.forEach((o) => {
            o.is_selected = value && o.name === value;
        });
        setJobRoles(jobRolesL);
    };

    const prepopulateShifts = (value) => {
        let shiftsL = [...shifts];
        shiftsL.forEach((shift) => {
            shift.is_selected = value && shift.name === value;
        });
        setShifts(shiftsL);
    };

    const prepopulateDepartments = (value) => {
        let departmentsL = [...departments];
        departmentsL.forEach((department) => {
            department.is_selected = value && department.name === value;
        });
        setDepartments(departmentsL);
    };

    const prepopulateAlpsCodes = (value) => {
        let alpsCodesL = [...alpsCodes];
        alpsCodesL.forEach((alpsCode) => {
            alpsCode.is_selected = value && alpsCode.name === value;
        });
        setAlpsCodes(alpsCodesL);
    };

    const prepopulateDaysOff = (value) => {
        let daysOffL = [...daysOff]
        daysOffL.forEach((day) => {
            day.is_selected = value && value.includes(day.name)
        })
        setDaysOff(daysOffL)
    }

    const prepopulateRLC = (value) => {
        let rlcApprovalIdL = {...rlcApprovalId};
        rlcApprovalIdL.isRequired = value !== constants.LABOR_ORDER_RED_LEVEL_STATUS.NO;
        let redLevelChangeL = [...redLevelChange];
        redLevelChangeL.forEach((o) => {
            o.is_selected = value && o.name === value;
        });
        if(rlcApprovalIdL.isRequired){
        let isRLCFromSwayamL = [...isRLCFromSwayam];
        isRLCFromSwayamL.forEach((obj)=>{ obj.is_selected = obj.name==='Enter approval Id';})
            setIsRLCFromSwayam(isRLCFromSwayamL);
        }
        setRedLevelChange(redLevelChangeL);
        setRlcApprovalId(rlcApprovalIdL);
    };

    const populateWatchersBySite = (wfsTeam) => {
        const defaultWatcher = JSON.parse(sessionStorage.getItem('session_user')).id +
                                    constants.WATCHER.EMAIL_SUFFIX;
        const siteWatchersList = wfsTeam.split(",")
            .map((wfs)=>{
                return wfs.trim() + constants.WATCHER.EMAIL_SUFFIX;}) //Filtering the default and already present values
            .filter((wfs) => wfs !== "None@amazon.com" && wfs !== defaultWatcher);
        setWatchersList([defaultWatcher,...siteWatchersList]);
    }

    const sitesChangeHandler = (event) => {
        setIsFormSubmitted(false);
        let value = event.target.value;
        setIsWatcherDisabled(value === "0");
        let sitesL = [...sites];
        sitesL.forEach((o) => {
            o.is_selected = value && o.id === value;
            if(o.is_selected === true && !params.id) {
                populateWatchersBySite(o.wfsTeam);
            }
        });
        setSites(sitesL);
    };

    const prepopulateSites = (siteValue,businessValue) => {
        let sitesL = [...sites];
        sitesL.forEach((o) => {
            o.is_selected = siteValue && o.name === siteValue;
        });
        sitesL = sitesL.filter(item => item.businessLine === businessValue);
        if(JSON.stringify(sites) !== JSON.stringify(sitesL)){
            setSites(sitesL);
        }
    };

    const businessLinesChangeHandler = (event) => {
        setIsFormSubmitted(false);
        let value = event.target.value;
        let businessLinesL = [...businessLines];
        businessLinesL.forEach((o) => {
            o.is_selected = value && o.id === value;
        });
        setBusinessLines(businessLinesL);
        setIsSitesDisabled(isBusinessLineSelected(businessLinesL));

        let sitesL = [...globalSites];
        sitesL = sitesL.filter(item => item.businessLine === value);
        setSites(sitesL);
    };

    const shiftsChangeHandler = (event) => {
        setIsFormSubmitted(false)
        let value = event.target.value;
        let shiftsL = [...shifts];
        shiftsL.forEach((shift) => {
            shift.is_selected = value && shift.id === value
        })
        setShifts(shiftsL)
    }

    const departmentsChangeHandler = (event) => {
        setIsFormSubmitted(false)
        let value = event.target.value;
        let departmentsL = [...departments];
        departmentsL.forEach((department) => {
            department.is_selected = value && department.id === value
        })
        setDepartments(departmentsL)
    }

    const alpsCodesChangeHandler = (event) => {
        setIsFormSubmitted(false)
        let value = event.target.value;
        let alpsCodesL = [...alpsCodes];
        alpsCodesL.forEach((alpsCode) => {
            alpsCode.is_selected = value && alpsCode.id === value
        })
        setAlpsCodes(alpsCodesL)
    }

    const daysOffChangeHandler = (event, value) => {
        setIsFormSubmitted(false)
        let daysOffL = [...daysOff];
        let selectedIds = value && value.length > 0 ? value.map((mapItem) => mapItem.name) : [];
        daysOffL.forEach((dayOff) => {
            dayOff.is_selected = selectedIds && selectedIds.length > 0 && selectedIds.includes(dayOff.name);
        });
        setDaysOff(daysOffL)
    }

    const prepopulateBusinessLines = (value) => {
        let businessLinesL = [...businessLines];
        businessLinesL.forEach((o) => {
            o.is_selected = value && o.id === value;
        });
        setBusinessLines(businessLinesL);
        setIsSitesDisabled(isBusinessLineSelected(businessLinesL));

    };

    const isBusinessLineSelected = (businessLines) => {
        let isDisabled = true;
        businessLines.forEach((m)=>{
            if(m.is_selected === true){
                isDisabled = false;
            }
        })
        return isDisabled;
    }

    const handleText = (e) => {
        let formDataL = { ...formData };
        if(e.target.name === "WomenCount" || e.target.name === "HeadCount"
            || e.target.name === "GeneralCount" || e.target.name === "TransgenderCount" || e.target.name === "DisabilityCount" || e.target.name === "Tenure") {
            let reg = new RegExp('^[0-9]*$');
            if(testPattern(e.target.value, reg)){
                if(isMenaUser() && e.target.name === "HeadCount") {
                    formDataL["GeneralCount"] = e.target.value
                }
                formDataL[e.target.name] = e.target.value;
                setFormData(formDataL);
            }
        }
        else if(e.target.name === "LaborOrderStartDate") {
            formDataL["LaborOrderEndDate"] = "";
            formDataL[e.target.name] = e.target.value;
            setFormData(formDataL);
        }
        else {
            formDataL[e.target.name] = e.target.value;
            setFormData(formDataL);
        }

    };
    const generateDuplicateLOsLink = (duplicateLOList) => {
       duplicateLOList =  duplicateLOList.map((duplicateLO, index) => {
           return (<span>
               <Link className="primary_color" onClick={() => (history.push(`/${constants.LABOR_ORDERS.ROUTE.VIEW}/${duplicateLO}`))}>
                {duplicateLO}
            </Link>  {index !== duplicateLOList.length -1 && <span>, </span>}
           </span>)})
        duplicateLOList.unshift(<span>Labor Order for this week (same Site, Hiring Week and Job Role) already exists - </span>)
        duplicateLOList.push(<span>. Do you confirm to create another Labor Order?</span>)
        return duplicateLOList
    }

    return (
        !isValidLaborOrderId ? <PageNotFound/> :
            <div className="amz_content_view mb-100">
            <Container maxWidth="lg">
                <Grid container alignItems={"center"} direction="row" justify="space-around">

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container direction="row" justify="flex-start" alignItems="center">
                        <div className={classes.root}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {params.relatedID && params.relatedName && (
                                    <Typography color="textPrimary">
                                        Create Related Activity
                                    </Typography>
                                )}
                                {!params.relatedID && !params.relatedName && (
                                    <Link className="primary_color"
                                          onClick={() =>
                                              history.push(`/${constants.LABOR_ORDERS.ROUTE.MAIN}`)
                                          }
                                    >
                                        {constants.LABOR_ORDERS.TITLE}
                                    </Link>
                                )}
                                {!params.id && (
                                    <Typography color="textPrimary">
                                        {constants.BUTTON.CREATE}{" "}
                                        {constants.LABOR_ORDERS.TITLE_SINGULAR}
                                    </Typography>
                                )}
                                {params.id && (
                                    <Typography color="textPrimary">
                                        {formData.LaborOrderId}
                                    </Typography>
                                )}
                            </Breadcrumbs>
                        </div>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container direction="row" justify="flex-end" alignItems="flex-start">
                        <Watcher  watchersList={watchersList} setWatchersList={setWatchersList}  isUpdateRequired={false}
                                  isWatcherDisabled={isWatcherDisabled} />
                        <ButtonSecondary Title="Cancel" onClick={cancelActivity} ButtonIcon={<CloseIcon />} tabIndex="0"/>
                        <ButtonPrimary Title={params.id ? "Update" : "Save"} onClick={formSubmit} ButtonIcon={<CheckIcon />} tabIndex="0"/>
                    </Grid>

                    {params.id ?  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container direction="row" justify="flex-end" alignItems="flex-start" style = {{marginTop: "1rem"}}>
                        <InputLabel Title="Send Notifications" />
                        <Switch style = {{color: "#007980"}} label={"switch"} onChange={() => {setIsNotificationNeeded(!isNotificationNeeded)}} checked={isNotificationNeeded} size="small"/>
                    </Grid> : null}

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container direction="row" justify="space-around" alignItems="center">
                        <div divider></div>
                        {params.id && <FormPageTItle Title={formData.ActivityName} />}
                        <div divider></div>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                        <FormPageSubHeading Title={constants.FORM_DETAILS.BASIC} />
                    </Grid>

                    {laborOrderLoading || (params.id && (sitesLoading || authorizedSitesLoading)) ? <CircularProgress/> :
                    <Grid container alignItems="top" className="form_group_list">
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Latest Need-by-Date:"/>
                                    <Formfieldstar/>
                                    <InfoHelpIcon
                                        FieldToolTip="The last date by which the LO needs to be fulfilled. All onboarding dates in LRs should be less than or equal this date."
                                        IconClassName={<InfoOutlinedIcon/>}
                                        Title="Latest Need-by-Date"
                                    />
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <FormInputDatePicker
                                        error
                                        style={{maxWidth: "175px"}}
                                        name="LaborOrderStartDate"
                                        value={formData?.LaborOrderStartDate}
                                        onChange={handleText}
                                        tabIndex="0"
                                        minDate={disablePastDate(new Date(), 0)}
                                    />
                                    {isFormSubmitted && formData.LaborOrderStartDate && new Date(formData.LaborOrderStartDate) < new Date(disablePastDate(new Date(), -1)) && (
                                        <FormPastDatevalidationerror fieldName="Latest Need-by-Date"/>
                                    )}
                                    {isFormSubmitted && !formData.LaborOrderStartDate && (
                                        <Formvalidationerror fieldName="Latest Need-by-Date"/>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                        {params.id && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Hiring Week:"/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    {<FormFieldView Content={formData.HiringWeek}/>}
                                </Grid>
                            </Grid>
                        </Grid>}

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Business line:"/>
                                    <Formfieldstar/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    {params.id ? <FormFieldView Content={formData.BusinessLine}/> :
                                        <SelectSingle
                                            error
                                            id="BusinessLines"
                                            name="BusinessLines"
                                            options={businessLines}
                                            handleChange={businessLinesChangeHandler}
                                            tab_index="0"
                                            fieldName="business lines"
                                        />
                                    }
                                    {isFormSubmitted && !params.id &&
                                        !businessLines.find((s) => s.is_selected) && (
                                            <Formvalidationerror fieldName="Business line"/>
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Site:"/>
                                    <Formfieldstar/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    {params.id ? <FormFieldView Content={formData.Site}/> :
                                        <SelectSingle
                                            isLoading={sitesLoading || authorizedSitesLoading}
                                            error
                                            id="Site"
                                            name="Site"
                                            options={sites}
                                            handleChange={sitesChangeHandler}
                                            isDisabled={isSitesDisabled}
                                            tab_index={isSitesDisabled ? "-1" : "0"}
                                            fieldName="sites"
                                        />
                                    }
                                    {isFormSubmitted && !params.id &&
                                        !sites.find((s) => s.is_selected) && (
                                            <Formvalidationerror fieldName="Site"/>
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Role:"/>
                                    <Formfieldstar/>
                                </Grid>
                                {
                                    relatedRequisitionsLoading ? <CircularProgress/>:isRoleEditable?<Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                        <SelectSingle
                                            error
                                            id="Job Role"
                                            name="Job Role"
                                            options={jobRoles}
                                            handleChange={jobRolesChangeHandler}
                                            tab_index="0"
                                            fieldName="job roles"
                                        />
                                        {isFormSubmitted &&
                                            !jobRoles.find((s) => s.is_selected) && (
                                                <Formvalidationerror fieldName="Job role"/>
                                            )}
                                    </Grid>:<FormFieldView Content={jobRoles.filter((o)=>o.is_selected)[0]?jobRoles.filter((o)=>o.is_selected)[0].name:""}/>
                                }

                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Head count:"/>
                                    <Formfieldstar/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <InputText
                                        error
                                        name="HeadCount"
                                        value={(formData && formData.HeadCount) ? formData.HeadCount : ""}
                                        onChange={handleText}
                                        FieldPlaceHolder="10"
                                        tabIndex="0"/>

                                    {isFormSubmitted && !formData.HeadCount && (
                                        <Formvalidationerror fieldName="Head count"/>
                                    )}
                                    {isFormSubmitted && parseInt(formData.HeadCount) < 1 && (
                                        <FormNonZerovalidationerror fieldName="Head count"/>
                                    )}

                                </Grid>
                            </Grid>
                        </Grid>
                        {isMenaUser() && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Shift:"/>
                                    <Formfieldstar/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <SelectSingle
                                        error
                                        id="Shifts"
                                        name="Shifts"
                                        options={shifts}
                                        handleChange={shiftsChangeHandler}
                                        tab_index="0"
                                        fieldName="shifts"
                                    />
                                    {isFormSubmitted && !params.id &&
                                        !shifts.find((s) => s.is_selected) && (
                                            <Formvalidationerror fieldName="Shift"/>
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>}
                        {isMenaUser() && <Grid container>
                           <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                        <InputLabel Title="Days Off:"/>
                                        <Formfieldstar/>
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                        <MultiAutoComplete
                                            error
                                            id="DaysOff"
                                            name="DaysOff"
                                            options={daysOff}
                                            onChange={daysOffChangeHandler}
                                            tab_index="0"
                                            fieldName="days off"
                                        />
                                        {isFormSubmitted && !params.id &&
                                            !daysOff.find((s) => s.is_selected) && (
                                                <Formvalidationerror fieldName="Days Off"/>
                                            )}
                                    </Grid>
                                </Grid>
                            </Grid>
                           <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                        <InputLabel Title="Cost center:"/>
                                        <Formfieldstar/>
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                        <InputText
                                            error
                                            name="CostCenter"
                                            value={(formData && formData.CostCenter) ? formData.CostCenter : ""}
                                            onChange={handleText}
                                            FieldPlaceHolder="1732"
                                            tabIndex="0"
                                        />
                                        {isFormSubmitted && !formData.CostCenter && (
                                            <Formvalidationerror fieldName="Cost center"/>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                        <InputLabel Title="Hiring manager:"/>
                                        <Formfieldstar/>
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                        <InputText
                                            error
                                            name="Manager"
                                            value={(formData && formData.Manager) ? formData.Manager : ""}
                                            onChange={handleText}
                                            FieldPlaceHolder="user alias"
                                            tabIndex="0"
                                        />
                                        {isFormSubmitted && !formData.Manager && (
                                            <Formvalidationerror fieldName="Manager"/>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                        <InputLabel Title="Department:"/>
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                        <SelectSingle
                                            error
                                            id="Department"
                                            name="Department"
                                            options={departments}
                                            handleChange={departmentsChangeHandler}
                                            tab_index="0"
                                            fieldName="departments"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                           <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                        <InputLabel Title="ALPS Code:"/>
                                        <Formfieldstar/>
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                        <SelectSingle
                                            error
                                            id="alpsCode"
                                            name="alpsCode"
                                            options={alpsCodes}
                                            handleChange={alpsCodesChangeHandler}
                                            tab_index="0"
                                            fieldName="alpsCodes"
                                        />
                                        {isFormSubmitted && !params.id &&
                                            !alpsCodes.find((alpsCode) => alpsCode.is_selected) && (
                                                <Formvalidationerror fieldName="Alps code"/>
                                            )}
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>}

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            {params.id && <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Balance head count:"/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    {<FormFieldView Content={formData.Balance}/>}
                                </Grid>
                            </Grid>}
                        </Grid>

                        { params.id && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Is Red Level Change:"/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <SelectSingle
                                        error
                                        id="IS_RLC"
                                        name="IsRedLevelChange"
                                        options={isRLCFromSwayam}
                                        handleChange={isRLCChangeHandler}
                                        tab_index="0"
                                        fieldName="IS RLC form Swayam"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>}

                        { params.id && (isRLCFromSwayam.find(obj => obj.name === "Enter approval Id").is_selected || formData.RlcApprovalId) && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="RLC Type:"/>
                                    <Formfieldstar/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <SelectSingle
                                        error
                                        id="RLC"
                                        name="RedLevelChange"
                                        options={redLevelChange}
                                        handleChange={redLevelChangeHandler}
                                        tab_index="0"
                                        fieldName="Red Level Change"
                                    />
                                    {isFormSubmitted &&
                                        !redLevelChange.find((s) => s.is_selected) && (
                                            <Formvalidationerror fieldName="Red Level Change"/>
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>}

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            {rlcApprovalId.isRequired &&
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                        <InputLabel Title="RLC Approval ID:"/>
                                        <Formfieldstar/>
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                        <InputText
                                            error
                                            name="RlcApprovalId"
                                            value={(formData && formData.RlcApprovalId) ? formData.RlcApprovalId : ""}
                                            onChange={handleText}
                                            FieldPlaceHolder="Enter Approval Id"
                                            tabIndex="0"/>
                                        {isFormSubmitted && rlcApprovalId.isRequired && (!formData.RlcApprovalId || /^\s*$/.test(formData.RlcApprovalId)) &&
                                            <Formvalidationerror fieldName="RLC Approval ID"/>
                                        }
                                    </Grid>
                                </Grid>}
                        </Grid>

                    </Grid>}

                    {!isMenaUser() && <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                        <FormPageSubHeading Title={constants.FORM_DETAILS.HEAD_COUNT} />
                    </Grid>}


                    {laborOrderLoading || (params.id && (sitesLoading || authorizedSitesLoading)) ? <CircularProgress/> :
                        !isMenaUser() &&  <Grid container alignItems="center" className="form_group_list">
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Male:"/>
                                    <Formfieldstar/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <InputText
                                        error
                                        name="GeneralCount"
                                        value={(formData && formData.GeneralCount) ? formData.GeneralCount : ""}
                                        onChange={handleText}
                                        FieldPlaceHolder="10"
                                        tabIndex="0"
                                    />
                                    {isFormSubmitted && !formData.GeneralCount && (
                                        <Formvalidationerror fieldName="Male"/>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Specially Abled:"/>
                                    <Formfieldstar/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <InputText
                                        error
                                        name="DisabilityCount"
                                        value={(formData && formData.DisabilityCount) ? formData.DisabilityCount : ""}
                                        onChange={handleText}
                                        FieldPlaceHolder="10"
                                        tabIndex="0"
                                    />
                                    {isFormSubmitted && !formData.DisabilityCount && (
                                        <Formvalidationerror fieldName="Specially Abled"/>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Female:"/>
                                    <Formfieldstar/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <InputText
                                        error
                                        name="WomenCount"
                                        value={(formData && formData.WomenCount) ? formData.WomenCount : ""}
                                        onChange={handleText}
                                        FieldPlaceHolder="10"
                                        tabIndex="0"
                                    />
                                    {isFormSubmitted && !formData.WomenCount && (
                                        <Formvalidationerror fieldName="Female"/>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                           <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Transgender:"/>
                                    <Formfieldstar/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <InputText
                                        error
                                        name="TransgenderCount"
                                        value={(formData && formData.TransgenderCount) ? formData.TransgenderCount : ""}
                                        onChange={handleText}
                                        FieldPlaceHolder="10"
                                        tabIndex="0"
                                    />
                                    {isFormSubmitted && !formData.TransgenderCount && (
                                        <Formvalidationerror fieldName="Transgender"/>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                        <FormPageSubHeading Title={constants.FORM_DETAILS.CONTRACT_DETAILS} />
                    </Grid>
                    {laborOrderLoading || (params.id && (sitesLoading || authorizedSitesLoading)) ? <CircularProgress/> :
                    <Grid container alignItems="center" className="form_group_list">
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Type of contract :"/>
                                    <Formfieldstar/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <FormControl>
                                        <RadioGroup name="ContractType" value={formData.ContractType}
                                                    onChange={handleText}>
                                            <FormControlLabel value="fixed" label="Fixed Tenure" control={<Radio/>}/>
                                            <FormControlLabel value="flexible" label="Flexible Tenure"
                                                              control={<Radio/>}/>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        {formData.ContractType === "fixed" && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="Tenure (days) :"/>
                                    <Formfieldstar/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <InputText
                                        error
                                        name="Tenure"
                                        value={(formData && formData.Tenure) ? formData.Tenure : ""}
                                        onChange={handleText}
                                        FieldPlaceHolder="10"
                                        tabIndex="0"
                                    />
                                    {isFormSubmitted && !formData.Tenure && (
                                        <Formvalidationerror fieldName="Tenure"/>
                                    )}

                                    {isFormSubmitted && parseInt(formData.Tenure) < 1 && (
                                        <FormNonZerovalidationerror fieldName="Tenure"/>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>}
                        {formData.ContractType === "flexible" && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                                    <InputLabel Title="End date:"/>
                                    <Formfieldstar/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <FormInputDatePicker
                                        error
                                        style={{maxWidth: "175px"}}
                                        name="LaborOrderEndDate"
                                        value={formData?.LaborOrderEndDate}
                                        onChange={handleText}
                                        tabIndex="0"
                                        isDisabled={!formData.LaborOrderStartDate}
                                        minDate={disablePastDate(new Date(formData.LaborOrderStartDate), 1)}
                                    />
                                    {isFormSubmitted && formData.ContractType === "flexible" && formData.LaborOrderEndDate && new Date(formData.LaborOrderEndDate) < new Date() && (
                                        <FormPastDatevalidationerror fieldName="End date"/>
                                    )}
                                    {isFormSubmitted && formData.ContractType === "flexible" && !formData.LaborOrderEndDate && (
                                        <Formvalidationerror fieldName="End date"/>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>}
                    </Grid>}
                </Grid>
            </Container>
                {showCreateRLCFlyout &&
                <CreateRLCModal LaborOrderIds={[{laborOrderId:params.id}]} site={[formData.Site]} isActive={showCreateRLCFlyout} onSuccess={()=>history.push(`/${constants.LABOR_ORDERS.ROUTE.VIEW}/${params.id}`)} onClose={resetRedLevelChangeHandler}/>
                }
                {laborOrderExistForSameHiringWeek &&
                    <FormActionAlertDialog Message={generateDuplicateLOsLink(duplicateLOs)} handleYes={() => params.id ? updateLaborOrder(laborOrderBody) : createLaborOrder(laborOrderBody)} handleNo={() => { setLaborOrderExistForSameHiringWeek(false); }} />
                }
                {showUnauthorizedDialog && (
                    <UnauthorizedAccessDialog
                        handleCancel={() => {
                            setShowUnauthorizedDialog(false);
                        }}
                        authorizationType={authType}
Ccc                        snackbarShowMessage={props.snackbarShowMessage}
                    />
                )}
        </div>
    );
}

export default withAlertSnackBar(CreateLaborOrder);