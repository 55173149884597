import React, {useState} from "react";
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import FormPageSubHeading from "../../../components/FormPage/FormPageSubHeading";
import InputLabel from "../../../components/FormPage/InputLabel";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory, useParams } from "react-router";
import FormFieldView from "../../../components/FormPage/FormFieldView";
import constants, {AuthorizationType} from "../../../Utils/Constants"
import { useEffect } from "react";
import { ActivityServices } from "../../../Services/activities";
import FormActionAlertDialog from "../../../components/FormPage/FormActionAlertDialog";
import Demo from "../../../components/DropdownMenu/customDropdownMenu"
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import UndoIcon from '@material-ui/icons/Undo';
import FilterListIcon from '@material-ui/icons/FilterList';
import { withAlertSnackBar } from "../../../HOComponents/AlertSnackBarHOC";
import { useLocation} from "react-router-dom";
import {
    CheckCircleSharp,
    SyncDisabled
} from "@material-ui/icons";
import {Card, CardActionArea, CardContent, Tab, Tabs} from "@mui/material";
import Watcher from "../../../components/DropdownMenu/WatchersDropdownMenu";
import PageNotFound from "../../PageNotFound/PageNotFound";
import InfoHelpIcon from "../../../components/FormPage/InfoHelpIcon";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CommentsLog from "../../Common/CommentsLog";
import UnauthorizedAccessDialog from "../../PageNotFound/UnauthorizedAccessDialog";
import { useQueryClient} from 'react-query'
import {useGetRelatedRequisitions} from "../../../ReactQuery/hooks/useGetRelatedRequisitions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useGetAllComments} from "../../../ReactQuery/hooks/useGetAllComments";
import {useGetLaborOrder} from "../../../ReactQuery/hooks/useGetLaborOrder";
import Divider from "@material-ui/core/Divider";
import AuditLogCards from "../../Common/AuditTrail/AuditLogCards";
import ApprovalIcon from "@mui/icons-material/Approval";
import CreateRLCModal from "../../../components/Modals/CreateRLCModal";
import * as Utils from "../../../Utils/Util";
import {useGetRlcStatus} from "../../../ReactQuery/hooks/useGetRlcStatus";
import {isMenaUser} from "../../../Utils/RegionUtil";
import {getUserCountry} from "../../../Utils/Util";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`related-tab-${index}`}
            aria-labelledby={`related-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function ViewLaborOrder(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        tabs: {
        "& .MuiTabs-indicator": {
            backgroundColor: "#006170",
            height: 3,
        },
        "& .MuiTab-root.Mui-selected": {
            color: '#006170'
        }
    }
    }));
    let history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const [formData, setFormData] = React.useState({});
    const [requisitions, setRequisitions] = React.useState([]);
    const params = useParams();
    const [showRemoveConfirmation, setShowRemoveConfirmation] = React.useState(false);
    const [showInactiveConfirmation,setShowInactiveConfirmation] = React.useState(false);
    const [showActiveConfirmation,setShowActiveConfirmation] = React.useState(false);
    const [watchersList, setWatchersList] = React.useState([]);
    const [isValidLaborOrderId,setIsValidLaborOrderId] = React.useState(true);
    const [candidateCount, setCandidateCount] = React.useState(0);
    const [fillRate, setFillRate] = React.useState(0);
    const [comments, setComments] = React.useState([]);
    const [showUnauthorizedDialog, setShowUnauthorizedDialog] = React.useState(false);
    const [authType, setAuthType] = React.useState("");
    const [showMarkFulfilledConfirmation, setMarkFulfilledConfirmation] = React.useState(false);
    const [showRevokeFulfilledConfirmation, setRevokeFulfilledConfirmation] = React.useState(false);
    const queryClient = useQueryClient();
    const [showCreateRLCFlyout, setShowCreateRLCFlyout] = useState(false);

    const {data: relatedRequisitions, isLoading: relatedRequisitionsLoading} = useGetRelatedRequisitions(params.id ? {laborOrderId: params.id} : null)
    const {data: queryComments, isLoading: commentsLoading} = useGetAllComments(params.id)
    const {data: queryLaborOrder, error: laborOrderError, isFetching: laborOrderLoading} = useGetLaborOrder(params.id, !(location.state && location.state.laborOrder))
    const {data: queryRlcStatus, error: rlcStatusError, isFetching: rlcStatusLoading} = useGetRlcStatus({approvalId:formData.RlcApprovalId,modifiedBy:JSON.parse(sessionStorage.getItem('session_user')).name}, (formData && !!formData.RlcApprovalId))
    const [tabValue, setTabValue] = React.useState(0);

    useEffect(() => {
        if (params.id) {
            prepopulate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryLaborOrder, laborOrderError])

    window.history.replaceState({}, document.title)

    useEffect(() => {
        let rows = relatedRequisitions && relatedRequisitions.data && relatedRequisitions.data.RequisitionInfos
        let rowsData = []
        let candidateCounter = 0
        let fillRateCounter = 0;
        if (rows ) {
            rows.forEach(row => {
                if(row.candidateCount && row.requisitionStatus === constants.STATUS.WIP_DB){
                    candidateCounter += row.candidateCount
                }
                rowsData.push({
                    requisitionId: row.requisitionId,
                    laborOrderId: row.laborOrderId,
                    headCount: row.headCount,
                    vendorName: row.vendorName,
                    status: row.requisitionStatus === constants.STATUS.WIP_DB ? constants.STATUS.WIP_FRONTEND : row.requisitionStatus,
                    candidateCount: row.candidateCount
                });
                fillRateCounter += row.fillRate ? row.fillRate : 0;
            });
            setCandidateCount(candidateCounter);
            setFillRate(fillRateCounter);
        }
        if(rowsData.length > 0) {
            setRequisitions(rowsData);
        }
    },[relatedRequisitions])

    useEffect(()=>{
       if(queryRlcStatus){
            let formDataL={...formData};
            formDataL.RlcStatus=queryRlcStatus.data.status;
            formDataL.RlcPendingWith=queryRlcStatus.data.pendingWith
            setFormData (formDataL);
        }
        // eslint-disable-next-line
    },[queryRlcStatus,rlcStatusError]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function LinkTab(props) {
        return (
            <Tab
                style={{textTransform:"capitalize"}}
                component="a"
                onClick={(event) => {
                    event.preventDefault();
                }}
                {...props}
            />
        );
    }

    const prepopulate = async () => {
        if(location.state && location.state.laborOrder){
            setWatchersList(location.state.laborOrder.wfsWatchersList)
            setFormData({...location.state.laborOrder})
            setIsValidLaborOrderId(true)
        }
        else{
            if(laborOrderError){
                if (laborOrderError && laborOrderError.data && (laborOrderError.data.message === constants.ERROR.LABOR_ORDER_NOT_FOUND_MESSAGE)) {
                    setIsValidLaborOrderId(false);
                }
                else if (laborOrderError && laborOrderError.data && (laborOrderError.data.message === constants.ERROR.UNAUTHORIZED_USER)) {
                    setShowUnauthorizedDialog(true);
                    setAuthType(AuthorizationType.SITE_BASED);
                }
                else if (laborOrderError && laborOrderError.data && (laborOrderError.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                    setShowUnauthorizedDialog(true);
                    setAuthType(AuthorizationType.BRASS)
                }
                else {
                    props.snackbarShowMessage(
                        `${constants.ERROR.PREPOPULATE_ERROR}`,
                        "error"
                    );
                }
            }
            else{
                let data = (queryLaborOrder && queryLaborOrder.data) || {};
                let formDataL = { ...formData };
                formDataL.LaborOrderId = data.laborOrderId ? data.laborOrderId : "0";
                formDataL.HeadCount = data.headCount ? data.headCount : "0";
                formDataL.GeneralCount = data.generalCount ? data.generalCount : "0";
                formDataL.WomenCount = data.womenCount ? data.womenCount : "0";
                formDataL.DisabilityCount = data.disabilityCount ? data.disabilityCount : "0";
                formDataL.TransgenderCount = data.transgenderCount ? data.transgenderCount : "0";
                formDataL.LaborOrderStartDate = data.startDate ? data.startDate : "";
                formDataL.CreatedDate = data.createdDate ? data.createdDate : "";
                formDataL.BusinessLine = data.businessLine ? data.businessLine : "";
                formDataL.Site = data.site ? data.site : "";
                formDataL.JobRole = data.jobRole ? data.jobRole : "";
                formDataL.RedLevelChange = data.redLevelChange ? data.redLevelChange : "NO";
                formDataL.RlcApprovalId = data.rlcApprovalId ? data.rlcApprovalId : "";
                formDataL.Balance = data.balance ? data.balance : "0";
                formDataL.GeneralBalance = data.generalBalance ? data.generalBalance : "0";
                formDataL.WomenBalance = data.womenBalance ? data.womenBalance : "0";
                formDataL.DisabilityBalance = data.disabilityBalance ? data.disabilityBalance : "0";
                formDataL.TransgenderBalance = data.transgenderBalance ? data.transgenderBalance : "0";
                formDataL.LaborOrderStatus = data.laborOrderStatus ? data.laborOrderStatus : "";
                formDataL.HiringWeek = data.hiringWeek ? data.hiringWeek : "";
                formDataL.wfsWatchersList = data.wfsWatchersList ? data.wfsWatchersList : [];
                formDataL.LaborOrderOldStatus = data.laborOrderOldStatus ? data.laborOrderOldStatus : null;
                formDataL.Tenure = data.tenure;
                formDataL.LaborOrderEndDate = data.endDate;
                formDataL.Shift = data.shift ? data.shift : "";
                formDataL.DaysOff = data.daysOff ? data.daysOff: "";
                formDataL.CostCenter = data.costCenter ? data.costCenter : "";
                formDataL.Manager = data.hiringManager ? data.hiringManager : "";
                formDataL.Department = data.department ? data.department : "";
                formDataL.AlpsCode = data.alpsCode ? data.alpsCode : "";
                formDataL.country = data.country? data.country : "IN";
                setWatchersList(formDataL.wfsWatchersList);
                setFormData(formDataL);
                setIsValidLaborOrderId(true);
            }

        }
    }

    const populateComments = async (commentsList) => {
            let rowsData = []
            if (commentsList){
                commentsList.forEach(row => {
                    rowsData.push({
                        createdDateTimestamp: row.createdDateTimestamp,
                        author: row.author,
                        date: row.date,
                        time: row.time,
                        comment: row.comment,
                        attachments: row.attachments,
                        vendorKamEmail: row.vendorKamEmail,
                        vendorName: row.vendorName,
                        status: row.status
                    })
                })
            }
            setComments(rowsData)
    }

    useEffect(() => {
        let rows = queryComments && queryComments.data && queryComments.data.comments
        populateComments(rows)

    },[queryComments])

    const requestBody={
        laborOrderId: formData.LaborOrderId,
        redLevelChange: formData.RedLevelChange,
        rlcApprovalId: formData.RlcApprovalId?formData.RlcApprovalId:null,
        headCount: parseInt(formData.HeadCount),
        generalCount: parseInt(formData.GeneralCount),
        womenCount: parseInt(formData.WomenCount),
        disabilityCount: parseInt(formData.DisabilityCount),
        transgenderCount: parseInt(formData.TransgenderCount),
        startDate: formData.LaborOrderStartDate,
        createdDate: formData.CreatedDate,
        businessLine: formData.BusinessLine,
        site: formData.Site,
        jobRole: formData.JobRole,
        laborOrderStatus: formData.LaborOrderStatus,
        wfsWatchersList: formData.wfsWatchersList,
        country: formData.country,
        shift: formData.Shift,
        alpsCode: formData.AlpsCode,
        department: formData.Department,
        hiringManager: formData.Manager,
        costCenter: formData.CostCenter,
        daysOff: formData.DaysOff
    };

    const updateWatcher = (updatedWatcherList) => {
        requestBody.wfsWatchersList = updatedWatcherList;
        if(formData.Tenure) {
            requestBody.tenure = formData.Tenure;
        }
        if(formData.LaborOrderEndDate) {
            requestBody.endDate = formData.LaborOrderEndDate;
        }
        queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)
        return ActivityServices.editActivity(requestBody);
    }

    const removeActivityConfirm = () => {
        setShowRemoveConfirmation(true);
    }

    const inactiveActivityConfirm = () =>{
        setShowInactiveConfirmation(true);
    }

    const activeActivityConfirm = () =>{
        setShowActiveConfirmation(true);
    }

    const removeActivity = () => {
        let body = {
            laborOrderId: params.id,
            createdDate: formData.CreatedDate,
            site: formData.Site
        };
        setShowRemoveConfirmation(false)
        ActivityServices.removeActivity(body).then((res) => {
            queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)
            queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
            props.snackbarShowMessage(`${constants.CRUD_SUCCESS_ALERTS.DELETED}`);
            window.setTimeout(() => {
                history.push(`/${constants.LABOR_ORDERS.ROUTE.MAIN}`);
            }, 1000)
        }).catch((error) => {
            if (error && error.data && (error.data.message === constants.ERROR.UNAUTHORIZED_USER)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.SITE_BASED);
            }
            else if (error && error.data && (error.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.BRASS);
            }
        })
    }

    const markInactive = () => {
        setShowInactiveConfirmation(false);
        requestBody.laborOrderStatus = "INACTIVE";
        requestBody.wfsWatchersList = watchersList;
        if(formData.Tenure) {
            requestBody.tenure = formData.Tenure;
        }
        if(formData.LaborOrderEndDate) {
            requestBody.endDate = formData.LaborOrderEndDate;
        }
        ActivityServices.editActivity(requestBody).then((res) => {
            queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)
            queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
            props.snackbarShowMessage(`${constants.CRUD_SUCCESS_ALERTS.UPDATED}`);
            window.setTimeout(() => {
                history.push(`/${constants.LABOR_ORDERS.ROUTE.MAIN}`);
            }, 1000)
        }).catch((error) => {
            if (error && error.data && (error.data.message === constants.ERROR.UNAUTHORIZED_USER)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.SITE_BASED);
            }
            else if (error && error.data && (error.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.BRASS);
            }
        })
    }

    const markActive = () => {
        setShowInactiveConfirmation(false);
        requestBody.laborOrderStatus = "OPEN";
        requestBody.wfsWatchersList = watchersList;
        if(formData.Tenure) {
            requestBody.tenure = formData.Tenure;
        }
        if(formData.LaborOrderEndDate) {
            requestBody.endDate = formData.LaborOrderEndDate;
        }
        ActivityServices.editActivity(requestBody).then((res) => {
            queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)
            queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
            props.snackbarShowMessage(`${constants.CRUD_SUCCESS_ALERTS.UPDATED}`);
            window.setTimeout(() => {
                history.push(`/${constants.LABOR_ORDERS.ROUTE.MAIN}`);
            }, 1000)
        }).catch((error) => {
            if (error && error.data && (error.data.message === constants.ERROR.UNAUTHORIZED_USER)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.SITE_BASED);
            }
            else if (error && error.data && (error.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.BRASS);
            }
        })
    }

    const addComment = (comment) => {
        ActivityServices.createComment(comment).then((res) => {
                let commentsList = res && res.data && res.data.comments
                populateComments(commentsList)
        }).catch((error) => {
            props.snackbarShowMessage(
                `${constants.ERROR.COMMENT_ADD_ERROR}`,
                "error"
            );
        })
    }

    const editComment = (comment) => {
        ActivityServices.updateComment(comment).then((res) => {
                let commentsList = res && res.data && res.data.comments
                populateComments(commentsList)
        }).catch((error) => {
            props.snackbarShowMessage(
                `${constants.ERROR.COMMENT_UPDATE_ERROR}`,
                "error"
            );
        })
    }

    const deleteComment = (comment) => {
        ActivityServices.updateComment(comment).then((res) => {
                let commentsList = res && res.data && res.data.comments
                populateComments(commentsList)
        }).catch((error) => {
            props.snackbarShowMessage(
                `${constants.ERROR.COMMENT_DELETE_ERROR}`,
                "error"
            );
        })
    }

    const editActivity = () => {
        history.push({pathname:`/${constants.LABOR_ORDERS.ROUTE.EDIT}/${params.id}`,
        state:{laborOrder: formData}});
    }
    const createRequisitionActivity = () => {
        history.push(`/${constants.REQUISITIONS.ROUTE.CREATE}/${constants.REQUISITIONS.ROUTE_RELATED}/${params.id}`);
    }
    const showRequisitionsActivity = () => {
        history.push(`/${constants.REQUISITIONS.ROUTE.MAIN}/${constants.REQUISITIONS.ROUTE_RELATED}/${formData.LaborOrderId}`);
    }
    const requisitionSelectActivity =(e,requisitionId) => {
       history.push(`/${constants.REQUISITIONS.ROUTE.VIEW}/${params.id}&${requisitionId}`);
    }

    const downloadCandidateData = async() => {

        const downloadCandidateRequest={
            laborOrderId: formData.LaborOrderId,
            headCount: parseInt(formData.HeadCount),
            generalCount: parseInt(formData.GeneralCount),
            womenCount: parseInt(formData.WomenCount),
            disabilityCount: parseInt(formData.DisabilityCount),
            transgenderCount: parseInt(formData.TransgenderCount),
            startDate: formData.LaborOrderStartDate,
            createdDate: formData.CreatedDate,
            businessLine: formData.BusinessLine,
            site: formData.Site,
            jobRole: formData.JobRole,
            laborOrderStatus: formData.LaborOrderStatus,
            wfsWatchersList: formData.wfsWatchersList,
            generalBalance : parseInt(formData.GeneralBalance),
            womenBalance : parseInt(formData.WomenBalance),
            transgenderBalance : parseInt(formData.TransgenderBalance),
            disabilityBalance : parseInt(formData.DisabilityBalance),
            tenure : parseInt(formData.Tenure),
            balance : parseInt(formData.Balance),
            endDate : formData.LaborOrderEndDate ? formData.LaborOrderEndDate : "",
            fillRate : fillRate,
            requisitionIds : [],
            country : getUserCountry()
        };
        
        downloadCandidateRequest.requisitionIds = requisitions.filter(requisition =>{
             return requisition.status === "Work In Progress" || requisition.status === "INACTIVE" || requisition.status === "FULFILLED";
         }).map(requisition => {
             return requisition.requisitionId;
         });

        await ActivityServices.downloadCandidateDataByLO(downloadCandidateRequest)
            .then((data)=>{
                props.snackbarShowMessage(`File successfully downloaded`);
                let link=document.createElement('a');
                link.download = data.config.data.replace(/[{}"]+/g, "").split(":")[1];
                link.href=data.data.candidateByLoS3Url;
                document.body.appendChild(link);
                link.click();
                setTimeout(() => {
                    document.body.removeChild(link);
                }, 200);
            })
            .catch((error)=>{
                props.snackbarShowMessage(`File download error ` ,"error");
            })
    
    }

    const markFulfilledActivityConfirm = () =>{
        setMarkFulfilledConfirmation(true);
    }

    const revokeFulfilledActivityConfirm = () =>{
        setRevokeFulfilledConfirmation(true);
    }

    const markAndRevokeFulfilledActivity = (status) => {
        setMarkFulfilledConfirmation(false);
        setRevokeFulfilledConfirmation(false);
        requestBody.laborOrderStatus = status;
        requestBody.wfsWatchersList = watchersList;
        if(formData.Tenure) {
            requestBody.tenure = formData.Tenure;
        }
        if(formData.LaborOrderEndDate) {
            requestBody.endDate = formData.LaborOrderEndDate;
        }
        ActivityServices.editActivity(requestBody).then((res) => {
            queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)
            props.snackbarShowMessage(`${constants.CRUD_SUCCESS_ALERTS.UPDATED}`);
            window.setTimeout(() => {
                history.push(`/${constants.LABOR_ORDERS.ROUTE.MAIN}`);
            }, 1000)
        }).catch((error) => {
        })
    }

    const laborOrderActions = [
        {
            id: "1",
            Title:`${ constants.BUTTON.EDIT + ' ' + constants.LABOR_ORDERS.TITLE_SINGULAR }` ,
            Icon: EditIcon,
            onClick: editActivity
        },
        {
            id: "2",
            Title: constants.BUTTON.INACTIVE,
            Icon: SyncDisabled,
            onClick: inactiveActivityConfirm
        },
        {
            id: "3",
            Title: constants.BUTTON.CREATE_REQUISITION,
            Icon: AddBoxIcon,
            onClick: createRequisitionActivity
        },
        {
            id: "4",
            Title: constants.BUTTON.DELETE,
            Icon: DeleteIcon,
            onClick: removeActivityConfirm
        },
        {
            id: "5",
            Title: constants.BUTTON.SHOW_RELATED,
            Icon: FilterListIcon,
            onClick: showRequisitionsActivity
        },
        {
            id: "6",
            Title: constants.BUTTON.ACTIVE,
            Icon: SyncDisabled,
            onClick: activeActivityConfirm
        },
        {
            id:"7",
            Title:constants.BUTTON.DOWNLOAD_CANDIDATE_DATA,
            Icon:ArrowDownward,
            onClick:downloadCandidateData
        },
        {
            id:"8",
            Title:constants.BUTTON.MARK_FULFILLED,
            Icon:CheckCircleSharp,
            onClick: markFulfilledActivityConfirm
        },
        {
            id:"9",
            Title:constants.BUTTON.REVOKE_FULFILLED,
            Icon:UndoIcon,
            onClick: revokeFulfilledActivityConfirm
        },
        {
            id: "10",
            Title: `${constants.BUTTON.CREATE_RLC}`,
            Icon: ApprovalIcon,
            onClick: () => {setShowCreateRLCFlyout(true)},
        },
    ]
    let laborActiveActions= laborOrderActions.slice(0,5).concat(laborOrderActions.slice(6,7)).concat(laborOrderActions.slice(9,10));

    const laborInactiveActions = laborOrderActions.slice(3,7)

    let laborFulfilledActions= laborOrderActions.slice(1,2).concat(laborOrderActions.slice(3,5)).concat(laborOrderActions.slice(6,7))

    let noRequisitionsLaborActiveActions= laborOrderActions.slice(0,4).concat(laborOrderActions.slice(9,10));

    //Fulfilled option would be available when Need By Date <= Today's Date
    if(new Date(formData.LaborOrderStartDate) <= new Date()) {
        laborActiveActions = laborActiveActions.concat(laborOrderActions.slice(7,8));
        noRequisitionsLaborActiveActions = noRequisitionsLaborActiveActions.concat(laborOrderActions.slice(7,8));
    }

    const noRequisitionsLaborInactiveActions=[
        laborOrderActions[5],
        laborOrderActions[3]
    ]

    let noRequisitionsLaborFulfilledActions= laborOrderActions.slice(1,2).concat(laborOrderActions.slice(3,4))

    if(formData.LaborOrderOldStatus !== null){
        laborFulfilledActions = laborFulfilledActions.concat(laborOrderActions.slice(8,9));
        noRequisitionsLaborFulfilledActions = noRequisitionsLaborFulfilledActions.concat(laborOrderActions.slice(8,9))

    }

    const getLaborOrderActionsByStatus = (LaborOrderStatus) => {
        if (LaborOrderStatus === "INACTIVE") {
            if (requisitions.length > 0) {
                return <Demo option={laborInactiveActions}/>
            } else {
                return <Demo option={noRequisitionsLaborInactiveActions}/>
            }
        } else if (LaborOrderStatus === constants.LABOR_ORDERS.STATUS.FULFILLED) {
            if (requisitions.length > 0) {
                return <Demo option={laborFulfilledActions}/>
            } else {
                return <Demo option={noRequisitionsLaborFulfilledActions}/>
            }
        } else {
            if (requisitions.length > 0) {
                return <Demo option={laborActiveActions}/>
            } else {
                return <Demo option={noRequisitionsLaborActiveActions}/>
            }
        }
    }

    return (
        !isValidLaborOrderId ? <PageNotFound/> :
        <div className='amz_content_view view_page_module mb-100 view_page'>
            <Box sx={{ width: '100%' }}>
                <Tabs value={tabValue} onChange={handleTabChange} className={classes.tabs} aria-label="laborOrder tabs">
                    <LinkTab label={constants.LABOR_ORDERS.TITLE_INFO} />
                    <LinkTab label={constants.AUDIT_TRAIL.TITLE} />
                </Tabs>
                <Divider id = "tabsDivider" style={{width:"100%",marginTop:"-0.1rem", height:"0", border: "1px solid #868C96"}}/>
            </Box>
            <TabPanel value={tabValue} index={0}>
            <Container id="viewLaborOrder" maxWidth="lg">
                <Grid container direction="row" justify="space-around" alignItems="center">
                    
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className={classes.root}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                <Link className="primary_color" onClick={() => (history.push(`/${constants.LABOR_ORDERS.ROUTE.MAIN}`))}>
                                    {constants.LABOR_ORDERS.ROUTE_GRID}
                                </Link>
                                <Typography color="textPrimary">{formData.LaborOrderId}</Typography>
                            </Breadcrumbs>
                        </div>
                    </Grid>

                    <Grid item xl={ 6 } lg={ 6 } md={ 6 } sm={ 12 } xs={ 12 }>
                        {formData.LaborOrderStatus !== "DELETED" &&
                            <div className="form_page_actions">
                                <Watcher watchersList={watchersList} setWatchersList={setWatchersList}
                                         updateWatcher={updateWatcher} isUpdateRequired={true} />
                                {getLaborOrderActionsByStatus(formData.LaborOrderStatus)}
                            </div>
                        }
                    </Grid>

                    {laborOrderLoading ?  <CircularProgress/> :
                    <Grid container alignItems="top" className="form_group_list">

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Labor order:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.LaborOrderId}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Role:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.JobRole}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Business line:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.BusinessLine}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Site:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.Site}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Latest Need-by-Date:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.LaborOrderStartDate}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Hiring Week:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.HiringWeek}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Head count:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.HeadCount}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Status:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.LaborOrderStatus}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Created Date:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.CreatedDate}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        {candidateCount > 0 && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Candidate Pool:"/>
                                    <InfoHelpIcon
                                        FieldToolTip="Total number of candidates uploaded by the Vendor (includes duplicates)"
                                        IconClassName={<InfoOutlinedIcon/>}
                                        Title="Type"
                                    />
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={candidateCount}/>
                                </Grid>
                            </Grid>
                        </Grid>}
                        {isMenaUser() && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Shift :"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.Shift}/>
                                </Grid>
                            </Grid>
                        </Grid>}
                        {isMenaUser() && <Grid container>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <InputLabel Title="Days Off :"/>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <FormFieldView Content={formData.DaysOff}/>
                                    </Grid>
                                </Grid>
                            </Grid>

                           <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <InputLabel Title="Cost Center :"/>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <FormFieldView Content={formData.CostCenter}/>
                                    </Grid>
                                </Grid>
                            </Grid>

                          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <InputLabel Title="Hiring manager :"/>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <FormFieldView Content={formData.Manager}/>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <InputLabel Title="Department :"/>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <FormFieldView Content={formData.Department}/>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <InputLabel Title="Alps Code :"/>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <FormFieldView Content={formData.AlpsCode}/>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>}
                        {!formData.RlcApprovalId && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Is Red Level Change:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.RedLevelChange}/>
                                </Grid>
                            </Grid>
                        </Grid>}

                        {formData.RlcApprovalId && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="RLC Type:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.RedLevelChange}/>
                                </Grid>
                            </Grid>
                        </Grid>}

                        {formData.RlcApprovalId && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="RLC Approval ID:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Link underline="always" target="_blank" href={Utils.getApprovalURL(formData.RlcApprovalId)}>{formData.RlcApprovalId}</Link>
                                </Grid>
                            </Grid>
                        </Grid>}

                        {formData.RlcApprovalId && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="RLC Approval Status:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {rlcStatusLoading ?  <CircularProgress size="1.3rem"/> : <FormFieldView Content={formData.RlcStatus ? formData.RlcStatus : "Not available"}/>}
                                </Grid>
                            </Grid>
                        </Grid>}

                        {formData.RlcApprovalId && <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                    <InputLabel Title="RLC Approval Pending With:"/>
                                </Grid>
                                <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                    {rlcStatusLoading ?  <CircularProgress size="1.3rem"/> : <FormFieldView Content={formData.RlcPendingWith ? formData.RlcPendingWith.length===0 ? "Not anyone" : formData.RlcPendingWith.join(", "):"Not available"}/>}
                                </Grid>
                            </Grid>
                        </Grid>}

                         <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Balance:"/>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.GeneralBalance}/>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>}





                    {!isMenaUser() && <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                        <FormPageSubHeading Title={constants.FORM_DETAILS.HEAD_COUNT} />
                    </Grid>}

                    {laborOrderLoading ? <CircularProgress/> :
                        !isMenaUser() && <Grid container alignItems="top" className="form_group_list">

                         <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                </Grid>

                                 <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                    <InputLabel Title="Total"/>
                                </Grid>

                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Balance"/>
                                </Grid>
                            </Grid>
                        </Grid>

                       <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={4} lg={3} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Male:"/>
                                </Grid>
                                <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                    <FormFieldView Content={formData.GeneralCount}/>
                                </Grid>
                                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                                    <FormFieldView Content={formData.GeneralBalance}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={4} lg={3} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Female:"/>
                                </Grid>
                                <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                    <FormFieldView Content={formData.WomenCount}/>
                                </Grid>
                                <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                    <FormFieldView Content={formData.WomenBalance}/>
                                </Grid>
                            </Grid>
                        </Grid>

                       <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={4} lg={3} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Specially Abled:"/>
                                </Grid>
                                <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                    <FormFieldView Content={formData.DisabilityCount}/>
                                </Grid>
                                <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                    <FormFieldView Content={formData.DisabilityBalance}/>
                                </Grid>
                            </Grid>
                        </Grid>


                       <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={4} lg={3} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Transgender:"/>
                                </Grid>
                                <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                    <FormFieldView Content={formData.TransgenderCount}/>
                                </Grid>
                                <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                    <FormFieldView Content={formData.TransgenderBalance}/>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>}

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                        <FormPageSubHeading Title={constants.FORM_DETAILS.CONTRACT_DETAILS} />
                    </Grid>
                    {laborOrderLoading ? <CircularProgress/> :
                    <Grid container alignItems="top" className="form_group_list">
                        {formData.Tenure && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="Tenure(days):" />
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.Tenure} />
                                </Grid>
                            </Grid>
                        </Grid>}
                        {formData.LaborOrderEndDate && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <InputLabel Title="End date:" />
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <FormFieldView Content={formData.LaborOrderEndDate} />
                                </Grid>
                            </Grid>
                        </Grid>}

                    </Grid>}

                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                    <FormPageSubHeading Title={constants.FORM_DETAILS.RELATED_REQUISITION} />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="left"
                    alignItems="center" spacing={2}
                    className="mb-15">
                {requisitions.map((requisition)=>(
                    <Grid key ={requisition.requisitionId} item xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Card sx={{ minWidth: 180, borderRadius: 4, border: 1, borderColor: '#007486' , borderBlockWidth: 3}} variant="outlined" >
                            <CardActionArea onClick={ (e)=> requisitionSelectActivity(e,requisition.requisitionId)}>
                            <CardContent>
                                <Grid container direction="row"  justifyContent="space-between"  >
                                <Typography sx={{ fontSize: 14 }}  gutterBottom>
                                    {requisition.requisitionId}
                                </Typography>
                                    <Typography sx={{ fontSize: 16 }} >
                                        {requisition.headCount}
                                    </Typography>
                                </Grid>
                                <Grid container direction="row"  justifyContent="space-between"  >
                                <Typography sx={{ fontSize: 16 }}  >
                                    {requisition.vendorName}
                                </Typography></Grid>
                                <Grid container direction="row"  justifyContent="space-between"  >
                                <Typography sx={{ fontSize: 14 }} style={{color: (requisition.status === "OPEN") ? '#007486': '#000000'}} >
                                     {`${requisition.status} ${requisition.status === constants.STATUS.WIP_FRONTEND ?` (${ requisition.candidateCount})` : ''}`}
                                </Typography>
                                </Grid>
                            </CardContent>
                                </CardActionArea>
                        </Card>
                    </Grid>
                ))}</Grid>
                <Grid container direction="row" justifyContent="center">
                {requisitions.length===0 && !relatedRequisitionsLoading && <Typography variant="h6">No Related Requisitions</Typography>}
                    {relatedRequisitionsLoading && <CircularProgress/>}</Grid>
                <CommentsLog isLoading={ commentsLoading} comments={comments} onDeleteComment={deleteComment} onEditComment={editComment} onAddComment={addComment} parentID={params.id} parentStatus={formData.LaborOrderStatus} country={formData.country}/>

            </Container>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <AuditLogCards config={{pk:params.id,sk:params.id,type:"Labor_Order"}}/>
            </TabPanel>
            {showRemoveConfirmation &&
            <FormActionAlertDialog Message="Deleting this labour order will automatically delete all the requisitions against it. Do you want to proceed?" handleYes={removeActivity} handleNo={() => { setShowRemoveConfirmation(false) }} />
            }
            {showInactiveConfirmation &&
                <FormActionAlertDialog Message="Marking inactive this labour order will automatically inactivate all the requisitions against it. Do you want to proceed?" handleYes={markInactive} handleNo={() => { setShowInactiveConfirmation(false) }} />
            }
            {showActiveConfirmation &&
                <FormActionAlertDialog Message="Marking active this labour order will automatically activate all the requisitions against it. Do you want to proceed?" handleYes={markActive} handleNo={() => { setShowActiveConfirmation(false) }} />
            }
            {showMarkFulfilledConfirmation &&
                <FormActionAlertDialog Message="Marking this labour order as fulfilled. Do you want to proceed?" handleYes={()=>markAndRevokeFulfilledActivity(constants.LABOR_ORDERS.STATUS.FULFILLED)} handleNo={() => { setMarkFulfilledConfirmation(false) }} />
            }
            {showRevokeFulfilledConfirmation &&
                <FormActionAlertDialog Message="Revoking fulfilled status for this labor order. Do you want to proceed?" handleYes={()=>markAndRevokeFulfilledActivity(formData.LaborOrderOldStatus)} handleNo={() => { setRevokeFulfilledConfirmation(false) }} />
            }
            {showUnauthorizedDialog && (
                <UnauthorizedAccessDialog
                    handleCancel={() => {
                        setShowUnauthorizedDialog(false);
                    }}
                    authorizationType={authType}
                    snackbarShowMessage={props.snackbarShowMessage}
                />
            )}
            {showCreateRLCFlyout &&
                <CreateRLCModal LaborOrderIds={[{laborOrderId:params.id}]} site={[formData.Site]} isActive={showCreateRLCFlyout} onSuccess={()=>window.location.reload()} onClose={()=>setShowCreateRLCFlyout(false)}/>
            }
        </div>
    )
}

export default withAlertSnackBar(ViewLaborOrder);