import React, {useEffect, useState} from "react";
import {paginate} from "../../Utils/LaborOrderGridOperations";
import CandidateDataTable from "../../components/DataTable/CandidateDataTable";
import {searchCandidate, sortCandidates} from "../../Utils/CandidateGridOperations";
import {getCandidateHiringStage} from "../../Utils/CandidateDataUtils";
import constants from "../../Utils/Constants";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {useDownloadCandidateData} from "../../ReactQuery/hooks/useDownloadCandidateData";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useQueryClient} from "react-query";

function CandidateDataGrid(props) {
    const [rowData, setRowData] = React.useState(null);
    const [totalRowsCount, setTotalRowsCount] = React.useState(0);
    const [paginationConfig, setPaginationConfig] = React.useState(props.config);
    const [globalRows, setGlobalRows] = React.useState([]);
    const [requestBody, setRequestBody] = useState(null)
    const tableHeader = [
        { id: 'applicationId', title: 'Application ID', sortable: true, headerStyle: { color: '#99a0aa', textTransform: 'capitalize' }, rowStyle: { color: '#99a0aa', textTransform: 'capitalize' }, visibility: true },
        { id: 'hiringStage', title: 'Hiring Stage', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
        { id: 'assessment', title: 'Assessment', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
        { id: 'bgvStatus', title: 'BGV Status', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
        { id: 'rehireFlag', title: 'Rehire Flag', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true }
    ]

    const {data: queryCandidateData, isFetching: candidatesLoading, error: candidatesError} = useDownloadCandidateData(requestBody)
    const queryClient = useQueryClient();

    useEffect(() =>{
        if(props.config.laborOrderId && props.config.requisitionId){
            setRequestBody({laborOrderId:props.config.laborOrderId,requisitionId:props.config.requisitionId})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.config.laborOrderId,props.config.requisitionId]);

    const handleSearchClick = (searchValue) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.searchValue = searchValue;
        paginationConfigL.searchColumn = searchValue ? 'site' : '';
        setPaginationConfig(paginationConfigL);
    }
    useEffect(() => {
        if (paginationConfig && paginationConfig.pageSize) {
            operateOnRows();
        }
        if(paginationConfig && paginationConfig.laborOrderId && paginationConfig.requisitionId){
            setRequestBody({laborOrderId:paginationConfig.laborOrderId,requisitionId:paginationConfig.requisitionId})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationConfig, globalRows])

    useEffect(() => {
        if ((props.config.searchValue && props.config.searchValue!=="") || props.config.searchValue==='') {
            handleSearchClick(props.config.searchValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.config.searchValue])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[queryCandidateData, candidatesError])

    useEffect(() => {
        setRowData(null)
    },[requestBody])

    const handleSort = (clickedColumn) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.sortColumn = clickedColumn;
        paginationConfigL.sortOrder = paginationConfigL.sortOrder && paginationConfigL.sortOrder === 'asc' ? 'desc' : 'asc';
        setPaginationConfig(paginationConfigL);
    };

    const handlePage = (page) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = page + 1;
        setPaginationConfig(paginationConfigL);
    };

    const handlePageSize = (pageSize) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.pageSize = pageSize;
        setPaginationConfig(paginationConfigL);
    };

    const operateOnRows = () => {
        let rowsData = [...globalRows];
        rowsData= sortCandidates(rowsData,paginationConfig.sortColumn,paginationConfig.sortOrder)
        rowsData = searchCandidate(rowsData, paginationConfig.searchValue);
        setTotalRowsCount(rowsData.length);
        rowsData = paginate(rowsData ,paginationConfig.pageSize, paginationConfig.pageNo);
        setRowData(rowsData);
    }

    const getData = () => {
        if(candidatesError){
            props.snackbarShowMessage("Error fetching candidates for this requisition", "error", "3000");
            setRowData([]);
            setGlobalRows([]);
            setTotalRowsCount(0);
            queryClient.invalidateQueries([constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS,null]);
        }
        else {
            let rows = queryCandidateData && queryCandidateData.data && queryCandidateData.data.candidates;
            if (!candidatesLoading)
                populateGrid(rows)
        }

    };

    const populateGrid = (rows) => {
        let rowsData = []
        if (rows) {
            rows.forEach(row => {
                rowsData.push({
                    applicationId: row.candidateId,
                    hiringStage: row.hiringStage? getCandidateHiringStage(row.hiringStage): constants.CANDIDATE_MANAGEMENT.HIRING_STAGES.APPLICATION_SUBMITTED,
                    assessment: (row.assessmentScore && row.assessmentResult && row.assessmentScore!=="-" && row.assessmentResult!=="-") ? `${row.assessmentResult} (${row.assessmentScore})` : "-",
                    bgvStatus: row.bgvStatus ? row.bgvStatus : "-",
                    rehireFlag : row.rehireFlag,
                    createdBy : row.createdBy ? row.createdBy : "",
                    onBoardedRequisition : row.onBoardedRequisition ? row.onBoardedRequisition : " - ",
                });
            });
        }
        setRowData(rowsData);
        setGlobalRows(rowsData);
        setTotalRowsCount(rowsData.length)
    }

    return (
        <div className='amz_datatable activities_grid'>
            <CandidateDataTable
                id="amz_CandidateDataTable"
                columnData={tableHeader}
                rowData={(candidatesLoading || !rowData)? [] :rowData}
                pageSize={paginationConfig.pageSize ? paginationConfig.pageSize : 25}
                handlePageSize={handlePageSize}
                pageNo={paginationConfig.pageNo ? paginationConfig.pageNo : 1}
                handlePage={handlePage}
                handleSort={handleSort}
                sortColumn={paginationConfig.sortColumn ? paginationConfig.sortColumn : null}
                sortOrder={paginationConfig.sortOrder ? paginationConfig.sortOrder : null}
                count={totalRowsCount}
                noDataFound={(candidatesLoading || !rowData)? <CircularProgress/> :'No Candidates Found!'}
            />
        </div>
    )
}

export default withAlertSnackBar(CandidateDataGrid)