import React, {forwardRef, useEffect, useState} from 'react'
import DataTable from '../../components/DataTable/DataTable'
import { useLocation, useHistory } from 'react-router-dom'
import constants, {AuthorizationType} from '../../Utils/Constants';
import {paginate, searchLaborOrder, sortLaborOrder} from "../../Utils/LaborOrderGridOperations";
import {customSortLaborOrder} from "../../Utils/customSort";
import UnauthorizedAccessDialog from "../PageNotFound/UnauthorizedAccessDialog";
import {useGetAllLaborOrders} from "../../ReactQuery/hooks/useGetAllLaborOrders";
import { useQueryClient} from 'react-query'
import {disablePastDate} from "../../Utils/DateUtil";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {filterListByCountry} from "../../Utils/RegionUtil";

function LaborOrdersGrid(props, ref) {

    const [rowData, setRowData] = useState(null);
    const [totalRowsCount, setTotalRowsCount] = useState(0);
    const [paginationConfig, setPaginationConfig] = useState(props.config);
    const [globalRows, setGlobalRows] = useState([]);
    const [showUnauthorizedDialog, setShowUnauthorizedDialog] = useState(false);
    const [authType, setAuthType] = React.useState("");
    const [requestBody, setRequestBody] = useState(null)

    const queryClient = useQueryClient()
    const {data: queryLaborOrders, error: errorGetAllLaborOrders, isFetching: laborOrdersLoading} = useGetAllLaborOrders(requestBody)

    let urlQueryParams = useLocation();
    let history = useHistory();
    let defaultSortColumn = 'id';
    let defaultSortOrder = 'DESC';

    const tableHeader = [
        { id: 'LaborOrderId', title: 'Labor Order', sortable: true, headerStyle: { color: '#99a0aa', textTransform: 'capitalize' }, rowStyle: { color: '#99a0aa', textTransform: 'capitalize' }, visibility: true },
        { id: 'HiringWeek', title: 'Hiring Week', sortable: true, headerStyle: { color: '#99a0aa', textTransform: 'capitalize' }, rowStyle: { color: '#99a0aa', textTransform: 'capitalize' }, visibility: true },
        { id: 'BusinessLine', title: 'Business Line', sortable: true, headerStyle: { color: '#99a0aa', textTransform: 'capitalize' }, rowStyle: { color: '#99a0aa', textTransform: 'capitalize' }, visibility: true },
        { id: 'Site', title: 'Site', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
        { id: 'JobRole', title: 'Role', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
        { id: 'headCount', title: 'Head Count', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
        { id: 'LaborOrderStartDate', title: 'Need by Date', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
        { id: 'status', title: 'Status', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
        { id: 'IsRLC', title: 'Is RLC', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true }
    ]

    useEffect(() => {
        getData();
        let params = new URLSearchParams(urlQueryParams.search);
        let paginationConfigL = {};
        paginationConfigL.sortColumn = params.get('sortColumn') ? params.get('sortColumn') : defaultSortColumn;
        paginationConfigL.sortOrder = params.get('sortOrder') ? params.get('sortOrder') : defaultSortOrder;
        paginationConfigL.pageNo = params.get('pageNo') ? parseInt(params.get('pageNo')) : 1;
        paginationConfigL.pageSize = params.get('pageSize') ? parseInt(params.get('pageSize')) : 10;
        paginationConfigL.searchColumn = params.get('searchColumn') ? params.get('searchColumn') : '';
        paginationConfigL.searchValue = params.get('searchValue') ? params.get('searchValue') : '';
        paginationConfigL.externalFilters = {jobRole: "", site: ""};
        if (params.get('sortColumn')) {
            paginationConfigL.sortColumn = params.get('sortColumn');
            paginationConfigL.sortOrder = params.get('sortOrder');
        }
        if (params.get('pageNo')) {
            paginationConfigL.pageNo = parseInt(params.get('pageNo'));
        }
        if (params.get('pageSize')) {
            paginationConfigL.pageSize = parseInt(params.get('pageSize'));
        }
        if (params.get("jobRole")) {
            paginationConfigL.externalFilters.jobRole = params.get("jobRole");
        }
        if (params.get("site")) {
            paginationConfigL.externalFilters.site = params.get("site");
        }
        setPaginationConfig(paginationConfigL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSearchClick = (searchValue) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.searchValue = searchValue;
        paginationConfigL.searchColumn = searchValue ? 'site' : '';
        setPaginationConfig(paginationConfigL);
    }

    const handleExternalFilters = (filters) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.externalFilters = filters;
        paginationConfigL.externalFiltersApplied = props.config.externalFiltersApplied;
        setPaginationConfig(paginationConfigL);
    }

    useEffect(() => {
        if (paginationConfig && paginationConfig.pageSize) {
            operateOnRows();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationConfig, globalRows])

    useEffect(() => {
        if ((props.config.searchValue && props.config.searchValue!=="") || props.config.searchValue==='') {
            handleSearchClick(props.config.searchValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.config.searchValue])

    useEffect(() => {
        if ((props.config.externalFilters)) {
            handleExternalFilters(props.config.externalFilters)
        }
        if(props.config.externalFiltersApplied || props.config.externalFilters.resetAppliedFilter)
            getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.config.externalFilters])

    useEffect(() => {
        setRowData(null)
    },[requestBody])

    useEffect(() => {
        if(errorGetAllLaborOrders){
            if (errorGetAllLaborOrders.data && (errorGetAllLaborOrders.data.message === constants.ERROR.NO_ACCESS_ERROR)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.SITE_BASED);
            }
            else if (errorGetAllLaborOrders.data && (errorGetAllLaborOrders.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.BRASS);
            }
            else if (errorGetAllLaborOrders.data && (errorGetAllLaborOrders.data.statusCode === constants.ERROR.CODE.PAYLOAD_TOO_LARGE)){
                props.snackbarShowMessage(
                    `${constants.ERROR.FETCHING_ERROR} ${errorGetAllLaborOrders.data.message}`,
                    "error",
                    "4000",
                    ""
                );
            }
            else if (errorGetAllLaborOrders.data && (errorGetAllLaborOrders.data.statusCode === constants.ERROR.CODE.GATEWAY_TIMEOUT)){
                props.snackbarShowMessage(
                    `${constants.ERROR.FETCHING_ERROR} ${constants.ERROR.BACKEND_TIMEOUT_ERROR}`,
                    "error",
                    "4000",
                    ""
                );
            }
            setRowData ([]);
            setGlobalRows ([]);
            setTotalRowsCount (0);
            queryClient.invalidateQueries([constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS,null])
        }
        else {
            const queryData = queryClient.queryCache
                .findAll(constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)
                .sort((a, b) => b.state.dataUpdatedAt - a.state.dataUpdatedAt)[0].state.data

            let rows = queryData && queryData.data && queryData.data.laborOrders;
            let filters = queryData && queryData.data && queryData.data.filterData;
            props.downloadLaborOrderHandler(rows);
            if(!laborOrdersLoading)
                populateGrid(rows,filters)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[queryLaborOrders, errorGetAllLaborOrders])

    const populateGrid = (rows, filters) => {
        let rowsData=[]
        if (rows) {
            rows.forEach (row => {
                rowsData.push ({
                    LaborOrderId : row.laborOrderId ? row.laborOrderId : "0",
                    HeadCount : row.headCount ? row.headCount : "0",
                    GeneralCount:row.generalCount ? row.generalCount : "0",
                    WomenCount: row.womenCount ? row.womenCount : "0",
                    DisabilityCount: row.disabilityCount ? row.disabilityCount : "0",
                    TransgenderCount : row.transgenderCount ? row.transgenderCount : "0",
                    LaborOrderStartDate : row.startDate ? row.startDate : "",
                    CreatedDate :row.createdDate ? row.createdDate : "",
                    BusinessLine : row.businessLine ? row.businessLine : "",
                    Site :row.site ? row.site : "",
                    JobRole : row.jobRole ? row.jobRole : "",
                    country: row.country ? row.country : constants.INDIA_REGION,
                    RedLevelChange :row.redLevelChange ? row.redLevelChange : "NO",
                    RlcApprovalId : row.rlcApprovalId ? row.rlcApprovalId : "",
                    IsRLC: row.rlcApprovalId ? "Y" : "N",
                    Balance : row.balance ? row.balance : "0",
                    GeneralBalance : row.generalBalance ? row.generalBalance : "0",
                    WomenBalance : row.womenBalance ? row.womenBalance : "0",
                    CostCenter: row.costCenter ? row.costCenter : "",
                    Manager: row.hiringManager ? row.hiringManager : "",
                    Department: row.department ? row.department : "",
                    AlpsCode: row.alpsCode ? row.alpsCode : "",
                    DaysOff: row.daysOff ? row.daysOff : "",
                    Shift: row.shift ? row.shift : "",
                    DisabilityBalance :row.disabilityBalance ? row.disabilityBalance : "0",
                    TransgenderBalance:row.transgenderBalance ? row.transgenderBalance : "0",
                    LaborOrderStatus :row.laborOrderStatus ? row.laborOrderStatus : "",
                    HiringWeek :row.hiringWeek ? row.hiringWeek : "-",
                    wfsWatchersList :row.wfsWatchersList ? row.wfsWatchersList : [],
                    LaborOrderOldStatus :row.laborOrderOldStatus ? row.laborOrderOldStatus : null,
                    Tenure: row.tenure ? row.tenure:null,
                    LaborOrderEndDate : row.endDate? row.endDate:null,
                    is_selected:false,

                    headCount: ((row.balance) ? String (row.balance) : "0") + "/" + ((row.headCount) ? String (row.headCount) : "0"),
                    id: row.laborOrderId,
                    status: row.laborOrderStatus,
                    statusPreferenceCode: constants.STATUS_PREFERENCE_CODES.LABOR_ORDERS[row.laborOrderStatus]
                });
            });
            rowsData = filterListByCountry(rowsData)
        }
        if(filters){
            props.updateFilter(filters);
        }
        else{
            let externalFilters = {}
            externalFilters.jobRoles=[];
            externalFilters.countries=[];
            externalFilters.sites=[];
            externalFilters.status = ["OPEN"]
            externalFilters.businessLine = ''
            externalFilters.startDate = disablePastDate(new Date(),-7);
            externalFilters.endDate = disablePastDate(new Date(), 21);
            externalFilters.startHiringWeek = "";
            externalFilters.endHiringWeek = "";
            externalFilters.resetAppliedFilter = true;
            props.updateFilter(externalFilters)
        }
        if(!laborOrdersLoading)
            setRowData (customSortLaborOrder (rowsData));
        props.setSelectedLaborOrdersList(rowsData)
        setGlobalRows (rowsData);
        setTotalRowsCount (rowsData.length)
    }

    const handleSort = (clickedColumn) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.sortColumn = clickedColumn;
        paginationConfigL.sortOrder = paginationConfigL.sortOrder && paginationConfigL.sortOrder === 'asc' ? 'desc' : 'asc';
        setPaginationConfig(paginationConfigL);
    };

    const handlePage = (page) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = page + 1;
        setPaginationConfig(paginationConfigL);
    };

    const handlePageSize = (pageSize) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.pageSize = pageSize;
        setPaginationConfig(paginationConfigL);
    };

    const operateOnRows = () => {
        let rowsData = [...globalRows];
        rowsData= sortLaborOrder(rowsData,paginationConfig.sortColumn,paginationConfig.sortOrder)
        rowsData = searchLaborOrder(rowsData, paginationConfig.searchValue);
        setTotalRowsCount(rowsData.length);
        rowsData = paginate(rowsData ,paginationConfig.pageSize, paginationConfig.pageNo);
        setRowData(rowsData);
    }

    const getData = async () => {
        let body = {
            filterBy :"LO",
            alias: JSON.parse(sessionStorage.getItem('session_user')).id
        };
        if(!props.config.externalFiltersApplied){
            body.resetAppliedFilter = props.config.externalFilters.resetAppliedFilter;
        }
        else {
            body = {...body,...props.config.externalFilters}
        }
        setRequestBody(body)

    };

    const getIndex = (rowId, rows, isSelectionNeeded) => {
        let selectedIndex = -1
        for(let index = 0; index < rows.length; index++) {
            if(rows[index].LaborOrderId === rowId) {
                if((isSelectionNeeded && rows[index].is_selected) || !isSelectionNeeded ) {
                    selectedIndex = index;
                    break;
                }
            }
        }
        return selectedIndex
    }

    const getRowsCheckedCount = (rowData) => {
        let checkedCount = 0;
        if(rowData)
        rowData.forEach(row => checkedCount = row.is_selected ?  checkedCount + 1: checkedCount);
        return checkedCount;
    }

    const handleSelectAllClick = (event) => {
        let newRowData = [...rowData];
        newRowData.forEach(row => row.is_selected = event.target.checked)
        setRowData(newRowData);
        props.setSelectedLaborOrdersList(globalRows)

    };

    const rowSelect = (e, row) => {
        const selectedIndex = getIndex(row.LaborOrderId, rowData,false);
        let newRowData = [...rowData];
        newRowData[selectedIndex].is_selected = !newRowData[selectedIndex].is_selected;
        setRowData(newRowData);
        props.setSelectedLaborOrdersList(globalRows)

    }

    const rowAction = (e, row) => {
        history.push({pathname:`/${constants.LABOR_ORDERS.ROUTE.VIEW}/${row.id}`, state:
                {laborOrder:row}});
    }

    return (
        <div className='amz_datatable activities_grid'>
            <DataTable
                isLaborOrder = {true}
                columnData={tableHeader}
                rowData={(laborOrdersLoading || !rowData)? [] :rowData}
                pageSize={paginationConfig.pageSize ? paginationConfig.pageSize : 25}
                handlePageSize={handlePageSize}
                pageNo={paginationConfig.pageNo ? paginationConfig.pageNo : 1}
                handlePage={handlePage}
                handleSort={handleSort}
                sortColumn={paginationConfig.sortColumn ? paginationConfig.sortColumn : null}
                sortOrder={paginationConfig.sortOrder ? paginationConfig.sortOrder : null}
                count={totalRowsCount}
                rowAction={rowAction}
                noDataFound={(laborOrdersLoading || !rowData)? <CircularProgress/> :'No Labor Orders Found'}
                getIndex = {getIndex}
                rowSelect = {rowSelect}
                getRowsCheckedCount = {getRowsCheckedCount}
                onSelectAllClick = {handleSelectAllClick}
            />
            {showUnauthorizedDialog && (
                <UnauthorizedAccessDialog
                    handleCancel={() => {
                        setShowUnauthorizedDialog(false);
                    }}
                    authorizationType={authType}
                    snackbarShowMessage={props.snackbarShowMessage}
                />
            )}
        </div>
    )
}

export default withAlertSnackBar(forwardRef(LaborOrdersGrid))
